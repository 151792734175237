/* eslint-disable @typescript-eslint/no-explicit-any */
export const getListIcons = (): any[] => {
	const data: any[] = [
		{label: 'nenhum', value: undefined},
		{label: '0', value: 'fa0'},
		{label: '1', value: 'fa1'},
		{label: '2', value: 'fa2'},
		{label: '3', value: 'fa3'},
		{label: '4', value: 'fa4'},
		{label: '5', value: 'fa5'},
		{label: '6', value: 'fa6'},
		{label: '7', value: 'fa7'},
		{label: '8', value: 'fa8'},
		{label: '9', value: 'fa9'},
		{label: 'a', value: 'faA'},
		{label: 'ad', value: 'faAd'},
		{label: 'add', value: 'faAdd'},
		{label: 'addressbook', value: 'faAddressBook'},
		{label: 'addresscard', value: 'faAddressCard'},
		{label: 'adjust', value: 'faAdjust'},
		{label: 'airfreshener', value: 'faAirFreshener'},
		{label: 'aligncenter', value: 'faAlignCenter'},
		{label: 'alignjustify', value: 'faAlignJustify'},
		{label: 'alignleft', value: 'faAlignLeft'},
		{label: 'alignright', value: 'faAlignRight'},
		{label: 'allergies', value: 'faAllergies'},
		{label: 'ambulance', value: 'faAmbulance'},
		{label: 'americansignlanguageinterpreting', value: 'faAmericanSignLanguageInterpreting'},
		{label: 'anchor', value: 'faAnchor'},
		{label: 'anchorcirclecheck', value: 'faAnchorCircleCheck'},
		{label: 'anchorcircleexclamation', value: 'faAnchorCircleExclamation'},
		{label: 'anchorcirclexmark', value: 'faAnchorCircleXmark'},
		{label: 'anchorlock', value: 'faAnchorLock'},
		{label: 'angledoubledown', value: 'faAngleDoubleDown'},
		{label: 'angledoubleleft', value: 'faAngleDoubleLeft'},
		{label: 'angledoubleright', value: 'faAngleDoubleRight'},
		{label: 'angledoubleup', value: 'faAngleDoubleUp'},
		{label: 'angledown', value: 'faAngleDown'},
		{label: 'angleleft', value: 'faAngleLeft'},
		{label: 'angleright', value: 'faAngleRight'},
		{label: 'anglesdown', value: 'faAnglesDown'},
		{label: 'anglesleft', value: 'faAnglesLeft'},
		{label: 'anglesright', value: 'faAnglesRight'},
		{label: 'anglesup', value: 'faAnglesUp'},
		{label: 'angleup', value: 'faAngleUp'},
		{label: 'angry', value: 'faAngry'},
		{label: 'ankh', value: 'faAnkh'},
		{label: 'applealt', value: 'faAppleAlt'},
		{label: 'applewhole', value: 'faAppleWhole'},
		{label: 'archive', value: 'faArchive'},
		{label: 'archway', value: 'faArchway'},
		{label: 'areachart', value: 'faAreaChart'},
		{label: 'arrowaltcircledown', value: 'faArrowAltCircleDown'},
		{label: 'arrowaltcircleleft', value: 'faArrowAltCircleLeft'},
		{label: 'arrowaltcircleright', value: 'faArrowAltCircleRight'},
		{label: 'arrowaltcircleup', value: 'faArrowAltCircleUp'},
		{label: 'arrowcircledown', value: 'faArrowCircleDown'},
		{label: 'arrowcircleleft', value: 'faArrowCircleLeft'},
		{label: 'arrowcircleright', value: 'faArrowCircleRight'},
		{label: 'arrowcircleup', value: 'faArrowCircleUp'},
		{label: 'arrowdown', value: 'faArrowDown'},
		{label: 'arrowdown19', value: 'faArrowDown19'},
		{label: 'arrowdown91', value: 'faArrowDown91'},
		{label: 'arrowdownaz', value: 'faArrowDownAZ'},
		{label: 'arrowdownlong', value: 'faArrowDownLong'},
		{label: 'arrowdownshortwide', value: 'faArrowDownShortWide'},
		{label: 'arrowdownupacrossline', value: 'faArrowDownUpAcrossLine'},
		{label: 'arrowdownuplock', value: 'faArrowDownUpLock'},
		{label: 'arrowdownwideshort', value: 'faArrowDownWideShort'},
		{label: 'arrowdownza', value: 'faArrowDownZA'},
		{label: 'arrowleft', value: 'faArrowLeft'},
		{label: 'arrowleftlong', value: 'faArrowLeftLong'},
		{label: 'arrowleftrotate', value: 'faArrowLeftRotate'},
		{label: 'arrowpointer', value: 'faArrowPointer'},
		{label: 'arrowright', value: 'faArrowRight'},
		{label: 'arrowrightarrowleft', value: 'faArrowRightArrowLeft'},
		{label: 'arrowrightfrombracket', value: 'faArrowRightFromBracket'},
		{label: 'arrowrightfromfile', value: 'faArrowRightFromFile'},
		{label: 'arrowrightlong', value: 'faArrowRightLong'},
		{label: 'arrowrightrotate', value: 'faArrowRightRotate'},
		{label: 'arrowrighttobracket', value: 'faArrowRightToBracket'},
		{label: 'arrowrighttocity', value: 'faArrowRightToCity'},
		{label: 'arrowrighttofile', value: 'faArrowRightToFile'},
		{label: 'arrowrotateback', value: 'faArrowRotateBack'},
		{label: 'arrowrotatebackward', value: 'faArrowRotateBackward'},
		{label: 'arrowrotateforward', value: 'faArrowRotateForward'},
		{label: 'arrowrotateleft', value: 'faArrowRotateLeft'},
		{label: 'arrowrotateright', value: 'faArrowRotateRight'},
		{label: 'arrows', value: 'faArrows'},
		{label: 'arrowsalt', value: 'faArrowsAlt'},
		{label: 'arrowsalth', value: 'faArrowsAltH'},
		{label: 'arrowsaltv', value: 'faArrowsAltV'},
		{label: 'arrowsdowntoline', value: 'faArrowsDownToLine'},
		{label: 'arrowsdowntopeople', value: 'faArrowsDownToPeople'},
		{label: 'arrowsh', value: 'faArrowsH'},
		{label: 'arrowsleftright', value: 'faArrowsLeftRight'},
		{label: 'arrowsleftrighttoline', value: 'faArrowsLeftRightToLine'},
		{label: 'arrowsrotate', value: 'faArrowsRotate'},
		{label: 'arrowsspin', value: 'faArrowsSpin'},
		{label: 'arrowssplitupandleft', value: 'faArrowsSplitUpAndLeft'},
		{label: 'arrowstocircle', value: 'faArrowsToCircle'},
		{label: 'arrowstodot', value: 'faArrowsToDot'},
		{label: 'arrowstoeye', value: 'faArrowsToEye'},
		{label: 'arrowsturnright', value: 'faArrowsTurnRight'},
		{label: 'arrowsturntodots', value: 'faArrowsTurnToDots'},
		{label: 'arrowsupdown', value: 'faArrowsUpDown'},
		{label: 'arrowsupdownleftright', value: 'faArrowsUpDownLeftRight'},
		{label: 'arrowsuptoline', value: 'faArrowsUpToLine'},
		{label: 'arrowsv', value: 'faArrowsV'},
		{label: 'arrowtrenddown', value: 'faArrowTrendDown'},
		{label: 'arrowtrendup', value: 'faArrowTrendUp'},
		{label: 'arrowturndown', value: 'faArrowTurnDown'},
		{label: 'arrowturnup', value: 'faArrowTurnUp'},
		{label: 'arrowup', value: 'faArrowUp'},
		{label: 'arrowup19', value: 'faArrowUp19'},
		{label: 'arrowup91', value: 'faArrowUp91'},
		{label: 'arrowupaz', value: 'faArrowUpAZ'},
		{label: 'arrowupfrombracket', value: 'faArrowUpFromBracket'},
		{label: 'arrowupfromgroundwater', value: 'faArrowUpFromGroundWater'},
		{label: 'arrowupfromwaterpump', value: 'faArrowUpFromWaterPump'},
		{label: 'arrowuplong', value: 'faArrowUpLong'},
		{label: 'arrowuprightdots', value: 'faArrowUpRightDots'},
		{label: 'arrowuprightfromsquare', value: 'faArrowUpRightFromSquare'},
		{label: 'arrowupshortwide', value: 'faArrowUpShortWide'},
		{label: 'arrowupwideshort', value: 'faArrowUpWideShort'},
		{label: 'arrowupza', value: 'faArrowUpZA'},
		{label: 'aslinterpreting', value: 'faAslInterpreting'},
		{label: 'assistivelisteningsystems', value: 'faAssistiveListeningSystems'},
		{label: 'asterisk', value: 'faAsterisk'},
		{label: 'at', value: 'faAt'},
		{label: 'atlas', value: 'faAtlas'},
		{label: 'atom', value: 'faAtom'},
		{label: 'audiodescription', value: 'faAudioDescription'},
		{label: 'australsign', value: 'faAustralSign'},
		{label: 'automobile', value: 'faAutomobile'},
		{label: 'award', value: 'faAward'},
		{label: 'b', value: 'faB'},
		{label: 'baby', value: 'faBaby'},
		{label: 'babycarriage', value: 'faBabyCarriage'},
		{label: 'backspace', value: 'faBackspace'},
		{label: 'backward', value: 'faBackward'},
		{label: 'backwardfast', value: 'faBackwardFast'},
		{label: 'backwardstep', value: 'faBackwardStep'},
		{label: 'bacon', value: 'faBacon'},
		{label: 'bacteria', value: 'faBacteria'},
		{label: 'bacterium', value: 'faBacterium'},
		{label: 'bagshopping', value: 'faBagShopping'},
		{label: 'bahai', value: 'faBahai'},
		{label: 'bahtsign', value: 'faBahtSign'},
		{label: 'balancescale', value: 'faBalanceScale'},
		{label: 'balancescaleleft', value: 'faBalanceScaleLeft'},
		{label: 'balancescaleright', value: 'faBalanceScaleRight'},
		{label: 'ban', value: 'faBan'},
		{label: 'bandage', value: 'faBandage'},
		{label: 'bandaid', value: 'faBandAid'},
		{label: 'bangladeshitakasign', value: 'faBangladeshiTakaSign'},
		{label: 'bank', value: 'faBank'},
		{label: 'bansmoking', value: 'faBanSmoking'},
		{label: 'barchart', value: 'faBarChart'},
		{label: 'barcode', value: 'faBarcode'},
		{label: 'bars', value: 'faBars'},
		{label: 'barsprogress', value: 'faBarsProgress'},
		{label: 'barsstaggered', value: 'faBarsStaggered'},
		{label: 'baseball', value: 'faBaseball'},
		{label: 'baseballball', value: 'faBaseballBall'},
		{label: 'baseballbatball', value: 'faBaseballBatBall'},
		{label: 'basketball', value: 'faBasketball'},
		{label: 'basketballball', value: 'faBasketballBall'},
		{label: 'basketshopping', value: 'faBasketShopping'},
		{label: 'bath', value: 'faBath'},
		{label: 'bathtub', value: 'faBathtub'},
		{label: 'battery', value: 'faBattery'},
		{label: 'battery0', value: 'faBattery0'},
		{label: 'battery2', value: 'faBattery2'},
		{label: 'battery3', value: 'faBattery3'},
		{label: 'battery4', value: 'faBattery4'},
		{label: 'battery5', value: 'faBattery5'},
		{label: 'batterycar', value: 'faBatteryCar'},
		{label: 'batteryempty', value: 'faBatteryEmpty'},
		{label: 'batteryfull', value: 'faBatteryFull'},
		{label: 'batteryhalf', value: 'faBatteryHalf'},
		{label: 'batteryquarter', value: 'faBatteryQuarter'},
		{label: 'batterythreequarters', value: 'faBatteryThreeQuarters'},
		{label: 'bed', value: 'faBed'},
		{label: 'bedpulse', value: 'faBedPulse'},
		{label: 'beer', value: 'faBeer'},
		{label: 'beermugempty', value: 'faBeerMugEmpty'},
		{label: 'bell', value: 'faBell'},
		{label: 'bellconcierge', value: 'faBellConcierge'},
		{label: 'bellslash', value: 'faBellSlash'},
		{label: 'beziercurve', value: 'faBezierCurve'},
		{label: 'bible', value: 'faBible'},
		{label: 'bicycle', value: 'faBicycle'},
		{label: 'biking', value: 'faBiking'},
		{label: 'binoculars', value: 'faBinoculars'},
		{label: 'biohazard', value: 'faBiohazard'},
		{label: 'birthdaycake', value: 'faBirthdayCake'},
		{label: 'bitcoinsign', value: 'faBitcoinSign'},
		{label: 'blackboard', value: 'faBlackboard'},
		{label: 'blender', value: 'faBlender'},
		{label: 'blenderphone', value: 'faBlenderPhone'},
		{label: 'blind', value: 'faBlind'},
		{label: 'blog', value: 'faBlog'},
		{label: 'bold', value: 'faBold'},
		{label: 'bolt', value: 'faBolt'},
		{label: 'boltlightning', value: 'faBoltLightning'},
		{label: 'bomb', value: 'faBomb'},
		{label: 'bone', value: 'faBone'},
		{label: 'bong', value: 'faBong'},
		{label: 'book', value: 'faBook'},
		{label: 'bookatlas', value: 'faBookAtlas'},
		{label: 'bookbible', value: 'faBookBible'},
		{label: 'bookbookmark', value: 'faBookBookmark'},
		{label: 'bookdead', value: 'faBookDead'},
		{label: 'bookjournalwhills', value: 'faBookJournalWhills'},
		{label: 'bookmark', value: 'faBookmark'},
		{label: 'bookmedical', value: 'faBookMedical'},
		{label: 'bookopen', value: 'faBookOpen'},
		{label: 'bookopenreader', value: 'faBookOpenReader'},
		{label: 'bookquran', value: 'faBookQuran'},
		{label: 'bookreader', value: 'faBookReader'},
		{label: 'bookskull', value: 'faBookSkull'},
		{label: 'booktanakh', value: 'faBookTanakh'},
		{label: 'borderall', value: 'faBorderAll'},
		{label: 'bordernone', value: 'faBorderNone'},
		{label: 'borderstyle', value: 'faBorderStyle'},
		{label: 'bordertopleft', value: 'faBorderTopLeft'},
		{label: 'borehole', value: 'faBoreHole'},
		{label: 'bottledroplet', value: 'faBottleDroplet'},
		{label: 'bottlewater', value: 'faBottleWater'},
		{label: 'bowlfood', value: 'faBowlFood'},
		{label: 'bowlingball', value: 'faBowlingBall'},
		{label: 'bowlrice', value: 'faBowlRice'},
		{label: 'box', value: 'faBox'},
		{label: 'boxarchive', value: 'faBoxArchive'},
		{label: 'boxes', value: 'faBoxes'},
		{label: 'boxesalt', value: 'faBoxesAlt'},
		{label: 'boxespacking', value: 'faBoxesPacking'},
		{label: 'boxesstacked', value: 'faBoxesStacked'},
		{label: 'boxopen', value: 'faBoxOpen'},
		{label: 'boxtissue', value: 'faBoxTissue'},
		{label: 'braille', value: 'faBraille'},
		{label: 'brain', value: 'faBrain'},
		{label: 'brazilianrealsign', value: 'faBrazilianRealSign'},
		{label: 'breadslice', value: 'faBreadSlice'},
		{label: 'bridge', value: 'faBridge'},
		{label: 'bridgecirclecheck', value: 'faBridgeCircleCheck'},
		{label: 'bridgecircleexclamation', value: 'faBridgeCircleExclamation'},
		{label: 'bridgecirclexmark', value: 'faBridgeCircleXmark'},
		{label: 'bridgelock', value: 'faBridgeLock'},
		{label: 'bridgewater', value: 'faBridgeWater'},
		{label: 'briefcase', value: 'faBriefcase'},
		{label: 'briefcaseclock', value: 'faBriefcaseClock'},
		{label: 'briefcasemedical', value: 'faBriefcaseMedical'},
		{label: 'broadcasttower', value: 'faBroadcastTower'},
		{label: 'broom', value: 'faBroom'},
		{label: 'broomball', value: 'faBroomBall'},
		{label: 'brush', value: 'faBrush'},
		{label: 'bucket', value: 'faBucket'},
		{label: 'bug', value: 'faBug'},
		{label: 'bugs', value: 'faBugs'},
		{label: 'bugslash', value: 'faBugSlash'},
		{label: 'building', value: 'faBuilding'},
		{label: 'buildingcirclearrowright', value: 'faBuildingCircleArrowRight'},
		{label: 'buildingcirclecheck', value: 'faBuildingCircleCheck'},
		{label: 'buildingcircleexclamation', value: 'faBuildingCircleExclamation'},
		{label: 'buildingcirclexmark', value: 'faBuildingCircleXmark'},
		{label: 'buildingcolumns', value: 'faBuildingColumns'},
		{label: 'buildingflag', value: 'faBuildingFlag'},
		{label: 'buildinglock', value: 'faBuildingLock'},
		{label: 'buildingngo', value: 'faBuildingNgo'},
		{label: 'buildingshield', value: 'faBuildingShield'},
		{label: 'buildingun', value: 'faBuildingUn'},
		{label: 'buildinguser', value: 'faBuildingUser'},
		{label: 'buildingwheat', value: 'faBuildingWheat'},
		{label: 'bullhorn', value: 'faBullhorn'},
		{label: 'bullseye', value: 'faBullseye'},
		{label: 'burger', value: 'faBurger'},
		{label: 'burn', value: 'faBurn'},
		{label: 'burst', value: 'faBurst'},
		{label: 'bus', value: 'faBus'},
		{label: 'busalt', value: 'faBusAlt'},
		{label: 'businesstime', value: 'faBusinessTime'},
		{label: 'bussimple', value: 'faBusSimple'},
		{label: 'c', value: 'faC'},
		{label: 'cab', value: 'faCab'},
		{label: 'cablecar', value: 'faCableCar'},
		{label: 'cake', value: 'faCake'},
		{label: 'cakecandles', value: 'faCakeCandles'},
		{label: 'calculator', value: 'faCalculator'},
		{label: 'calendar', value: 'faCalendar'},
		{label: 'calendaralt', value: 'faCalendarAlt'},
		{label: 'calendarcheck', value: 'faCalendarCheck'},
		{label: 'calendarday', value: 'faCalendarDay'},
		{label: 'calendardays', value: 'faCalendarDays'},
		{label: 'calendarminus', value: 'faCalendarMinus'},
		{label: 'calendarplus', value: 'faCalendarPlus'},
		{label: 'calendartimes', value: 'faCalendarTimes'},
		{label: 'calendarweek', value: 'faCalendarWeek'},
		{label: 'calendarxmark', value: 'faCalendarXmark'},
		{label: 'camera', value: 'faCamera'},
		{label: 'cameraalt', value: 'faCameraAlt'},
		{label: 'cameraretro', value: 'faCameraRetro'},
		{label: 'camerarotate', value: 'faCameraRotate'},
		{label: 'campground', value: 'faCampground'},
		{label: 'cancel', value: 'faCancel'},
		{label: 'candycane', value: 'faCandyCane'},
		{label: 'cannabis', value: 'faCannabis'},
		{label: 'capsules', value: 'faCapsules'},
		{label: 'car', value: 'faCar'},
		{label: 'caralt', value: 'faCarAlt'},
		{label: 'caravan', value: 'faCaravan'},
		{label: 'carbattery', value: 'faCarBattery'},
		{label: 'carburst', value: 'faCarBurst'},
		{label: 'carcrash', value: 'faCarCrash'},
		{label: 'caretdown', value: 'faCaretDown'},
		{label: 'caretleft', value: 'faCaretLeft'},
		{label: 'caretright', value: 'faCaretRight'},
		{label: 'caretsquaredown', value: 'faCaretSquareDown'},
		{label: 'caretsquareleft', value: 'faCaretSquareLeft'},
		{label: 'caretsquareright', value: 'faCaretSquareRight'},
		{label: 'caretsquareup', value: 'faCaretSquareUp'},
		{label: 'caretup', value: 'faCaretUp'},
		{label: 'caron', value: 'faCarOn'},
		{label: 'carrear', value: 'faCarRear'},
		{label: 'carriagebaby', value: 'faCarriageBaby'},
		{label: 'carrot', value: 'faCarrot'},
		{label: 'carside', value: 'faCarSide'},
		{label: 'cartarrowdown', value: 'faCartArrowDown'},
		{label: 'cartflatbed', value: 'faCartFlatbed'},
		{label: 'cartflatbedsuitcase', value: 'faCartFlatbedSuitcase'},
		{label: 'cartplus', value: 'faCartPlus'},
		{label: 'cartshopping', value: 'faCartShopping'},
		{label: 'cartunnel', value: 'faCarTunnel'},
		{label: 'cashregister', value: 'faCashRegister'},
		{label: 'cat', value: 'faCat'},
		{label: 'cedisign', value: 'faCediSign'},
		{label: 'centsign', value: 'faCentSign'},
		{label: 'certificate', value: 'faCertificate'},
		{label: 'chain', value: 'faChain'},
		{label: 'chainbroken', value: 'faChainBroken'},
		{label: 'chainslash', value: 'faChainSlash'},
		{label: 'chair', value: 'faChair'},
		{label: 'chalkboard', value: 'faChalkboard'},
		{label: 'chalkboardteacher', value: 'faChalkboardTeacher'},
		{label: 'chalkboarduser', value: 'faChalkboardUser'},
		{label: 'champagneglasses', value: 'faChampagneGlasses'},
		{label: 'chargingstation', value: 'faChargingStation'},
		{label: 'chartarea', value: 'faChartArea'},
		{label: 'chartbar', value: 'faChartBar'},
		{label: 'chartcolumn', value: 'faChartColumn'},
		{label: 'chartdiagram', value: 'faChartDiagram'},
		{label: 'chartgantt', value: 'faChartGantt'},
		{label: 'chartline', value: 'faChartLine'},
		{label: 'chartpie', value: 'faChartPie'},
		{label: 'chartsimple', value: 'faChartSimple'},
		{label: 'check', value: 'faCheck'},
		{label: 'checkcircle', value: 'faCheckCircle'},
		{label: 'checkdouble', value: 'faCheckDouble'},
		{label: 'checksquare', value: 'faCheckSquare'},
		{label: 'checktoslot', value: 'faCheckToSlot'},
		{label: 'cheese', value: 'faCheese'},
		{label: 'chess', value: 'faChess'},
		{label: 'chessbishop', value: 'faChessBishop'},
		{label: 'chessboard', value: 'faChessBoard'},
		{label: 'chessking', value: 'faChessKing'},
		{label: 'chessknight', value: 'faChessKnight'},
		{label: 'chesspawn', value: 'faChessPawn'},
		{label: 'chessqueen', value: 'faChessQueen'},
		{label: 'chessrook', value: 'faChessRook'},
		{label: 'chevroncircledown', value: 'faChevronCircleDown'},
		{label: 'chevroncircleleft', value: 'faChevronCircleLeft'},
		{label: 'chevroncircleright', value: 'faChevronCircleRight'},
		{label: 'chevroncircleup', value: 'faChevronCircleUp'},
		{label: 'chevrondown', value: 'faChevronDown'},
		{label: 'chevronleft', value: 'faChevronLeft'},
		{label: 'chevronright', value: 'faChevronRight'},
		{label: 'chevronup', value: 'faChevronUp'},
		{label: 'child', value: 'faChild'},
		{label: 'childcombatant', value: 'faChildCombatant'},
		{label: 'childdress', value: 'faChildDress'},
		{label: 'childreaching', value: 'faChildReaching'},
		{label: 'children', value: 'faChildren'},
		{label: 'childrifle', value: 'faChildRifle'},
		{label: 'church', value: 'faChurch'},
		{label: 'circle', value: 'faCircle'},
		{label: 'circlearrowdown', value: 'faCircleArrowDown'},
		{label: 'circlearrowleft', value: 'faCircleArrowLeft'},
		{label: 'circlearrowright', value: 'faCircleArrowRight'},
		{label: 'circlearrowup', value: 'faCircleArrowUp'},
		{label: 'circlecheck', value: 'faCircleCheck'},
		{label: 'circlechevrondown', value: 'faCircleChevronDown'},
		{label: 'circlechevronleft', value: 'faCircleChevronLeft'},
		{label: 'circlechevronright', value: 'faCircleChevronRight'},
		{label: 'circlechevronup', value: 'faCircleChevronUp'},
		{label: 'circledollartoslot', value: 'faCircleDollarToSlot'},
		{label: 'circledot', value: 'faCircleDot'},
		{label: 'circledown', value: 'faCircleDown'},
		{label: 'circleexclamation', value: 'faCircleExclamation'},
		{label: 'circleh', value: 'faCircleH'},
		{label: 'circlehalfstroke', value: 'faCircleHalfStroke'},
		{label: 'circleinfo', value: 'faCircleInfo'},
		{label: 'circleleft', value: 'faCircleLeft'},
		{label: 'circleminus', value: 'faCircleMinus'},
		{label: 'circlenodes', value: 'faCircleNodes'},
		{label: 'circlenotch', value: 'faCircleNotch'},
		{label: 'circlepause', value: 'faCirclePause'},
		{label: 'circleplay', value: 'faCirclePlay'},
		{label: 'circleplus', value: 'faCirclePlus'},
		{label: 'circlequestion', value: 'faCircleQuestion'},
		{label: 'circleradiation', value: 'faCircleRadiation'},
		{label: 'circleright', value: 'faCircleRight'},
		{label: 'circlestop', value: 'faCircleStop'},
		{label: 'circleup', value: 'faCircleUp'},
		{label: 'circleuser', value: 'faCircleUser'},
		{label: 'circlexmark', value: 'faCircleXmark'},
		{label: 'city', value: 'faCity'},
		{label: 'clapperboard', value: 'faClapperboard'},
		{label: 'clinicmedical', value: 'faClinicMedical'},
		{label: 'clipboard', value: 'faClipboard'},
		{label: 'clipboardcheck', value: 'faClipboardCheck'},
		{label: 'clipboardlist', value: 'faClipboardList'},
		{label: 'clipboardquestion', value: 'faClipboardQuestion'},
		{label: 'clipboarduser', value: 'faClipboardUser'},
		{label: 'clock', value: 'faClock'},
		{label: 'clockfour', value: 'faClockFour'},
		{label: 'clockrotateleft', value: 'faClockRotateLeft'},
		{label: 'clone', value: 'faClone'},
		{label: 'close', value: 'faClose'},
		{label: 'closedcaptioning', value: 'faClosedCaptioning'},
		{label: 'cloud', value: 'faCloud'},
		{label: 'cloudarrowdown', value: 'faCloudArrowDown'},
		{label: 'cloudarrowup', value: 'faCloudArrowUp'},
		{label: 'cloudbolt', value: 'faCloudBolt'},
		{label: 'clouddownload', value: 'faCloudDownload'},
		{label: 'clouddownloadalt', value: 'faCloudDownloadAlt'},
		{label: 'cloudmeatball', value: 'faCloudMeatball'},
		{label: 'cloudmoon', value: 'faCloudMoon'},
		{label: 'cloudmoonrain', value: 'faCloudMoonRain'},
		{label: 'cloudrain', value: 'faCloudRain'},
		{label: 'cloudshowersheavy', value: 'faCloudShowersHeavy'},
		{label: 'cloudshowerswater', value: 'faCloudShowersWater'},
		{label: 'cloudsun', value: 'faCloudSun'},
		{label: 'cloudsunrain', value: 'faCloudSunRain'},
		{label: 'cloudupload', value: 'faCloudUpload'},
		{label: 'clouduploadalt', value: 'faCloudUploadAlt'},
		{label: 'clover', value: 'faClover'},
		{label: 'cny', value: 'faCny'},
		{label: 'cocktail', value: 'faCocktail'},
		{label: 'code', value: 'faCode'},
		{label: 'codebranch', value: 'faCodeBranch'},
		{label: 'codecommit', value: 'faCodeCommit'},
		{label: 'codecompare', value: 'faCodeCompare'},
		{label: 'codefork', value: 'faCodeFork'},
		{label: 'codemerge', value: 'faCodeMerge'},
		{label: 'codepullrequest', value: 'faCodePullRequest'},
		{label: 'coffee', value: 'faCoffee'},
		{label: 'cog', value: 'faCog'},
		{label: 'cogs', value: 'faCogs'},
		{label: 'coins', value: 'faCoins'},
		{label: 'colonsign', value: 'faColonSign'},
		{label: 'columns', value: 'faColumns'},
		{label: 'comment', value: 'faComment'},
		{label: 'commentalt', value: 'faCommentAlt'},
		{label: 'commentdollar', value: 'faCommentDollar'},
		{label: 'commentdots', value: 'faCommentDots'},
		{label: 'commenting', value: 'faCommenting'},
		{label: 'commentmedical', value: 'faCommentMedical'},
		{label: 'commentnodes', value: 'faCommentNodes'},
		{label: 'comments', value: 'faComments'},
		{label: 'commentsdollar', value: 'faCommentsDollar'},
		{label: 'commentslash', value: 'faCommentSlash'},
		{label: 'commentsms', value: 'faCommentSms'},
		{label: 'compactdisc', value: 'faCompactDisc'},
		{label: 'compass', value: 'faCompass'},
		{label: 'compassdrafting', value: 'faCompassDrafting'},
		{label: 'compress', value: 'faCompress'},
		{label: 'compressalt', value: 'faCompressAlt'},
		{label: 'compressarrowsalt', value: 'faCompressArrowsAlt'},
		{label: 'computer', value: 'faComputer'},
		{label: 'computermouse', value: 'faComputerMouse'},
		{label: 'conciergebell', value: 'faConciergeBell'},
		{label: 'contactbook', value: 'faContactBook'},
		{label: 'contactcard', value: 'faContactCard'},
		{label: 'cookie', value: 'faCookie'},
		{label: 'cookiebite', value: 'faCookieBite'},
		{label: 'copy', value: 'faCopy'},
		{label: 'copyright', value: 'faCopyright'},
		{label: 'couch', value: 'faCouch'},
		{label: 'cow', value: 'faCow'},
		{label: 'creditcard', value: 'faCreditCard'},
		{label: 'creditcardalt', value: 'faCreditCardAlt'},
		{label: 'crop', value: 'faCrop'},
		{label: 'cropalt', value: 'faCropAlt'},
		{label: 'cropsimple', value: 'faCropSimple'},
		{label: 'cross', value: 'faCross'},
		{label: 'crosshairs', value: 'faCrosshairs'},
		{label: 'crow', value: 'faCrow'},
		{label: 'crown', value: 'faCrown'},
		{label: 'crutch', value: 'faCrutch'},
		{label: 'cruzeirosign', value: 'faCruzeiroSign'},
		{label: 'cube', value: 'faCube'},
		{label: 'cubes', value: 'faCubes'},
		{label: 'cubesstacked', value: 'faCubesStacked'},
		{label: 'cut', value: 'faCut'},
		{label: 'cutlery', value: 'faCutlery'},
		{label: 'd', value: 'faD'},
		{label: 'dashboard', value: 'faDashboard'},
		{label: 'database', value: 'faDatabase'},
		{label: 'deaf', value: 'faDeaf'},
		{label: 'deafness', value: 'faDeafness'},
		{label: 'dedent', value: 'faDedent'},
		{label: 'deleteleft', value: 'faDeleteLeft'},
		{label: 'democrat', value: 'faDemocrat'},
		{label: 'desktop', value: 'faDesktop'},
		{label: 'desktopalt', value: 'faDesktopAlt'},
		{label: 'dharmachakra', value: 'faDharmachakra'},
		{label: 'diagnoses', value: 'faDiagnoses'},
		{label: 'diagramnext', value: 'faDiagramNext'},
		{label: 'diagrampredecessor', value: 'faDiagramPredecessor'},
		{label: 'diagramproject', value: 'faDiagramProject'},
		{label: 'diagramsuccessor', value: 'faDiagramSuccessor'},
		{label: 'diamond', value: 'faDiamond'},
		{label: 'diamondturnright', value: 'faDiamondTurnRight'},
		{label: 'dice', value: 'faDice'},
		{label: 'diced20', value: 'faDiceD20'},
		{label: 'diced6', value: 'faDiceD6'},
		{label: 'dicefive', value: 'faDiceFive'},
		{label: 'dicefour', value: 'faDiceFour'},
		{label: 'diceone', value: 'faDiceOne'},
		{label: 'dicesix', value: 'faDiceSix'},
		{label: 'dicethree', value: 'faDiceThree'},
		{label: 'dicetwo', value: 'faDiceTwo'},
		{label: 'digging', value: 'faDigging'},
		{label: 'digitaltachograph', value: 'faDigitalTachograph'},
		{label: 'directions', value: 'faDirections'},
		{label: 'disease', value: 'faDisease'},
		{label: 'display', value: 'faDisplay'},
		{label: 'divide', value: 'faDivide'},
		{label: 'dizzy', value: 'faDizzy'},
		{label: 'dna', value: 'faDna'},
		{label: 'dog', value: 'faDog'},
		{label: 'dollar', value: 'faDollar'},
		{label: 'dollarsign', value: 'faDollarSign'},
		{label: 'dolly', value: 'faDolly'},
		{label: 'dollybox', value: 'faDollyBox'},
		{label: 'dollyflatbed', value: 'faDollyFlatbed'},
		{label: 'donate', value: 'faDonate'},
		{label: 'dongsign', value: 'faDongSign'},
		{label: 'doorclosed', value: 'faDoorClosed'},
		{label: 'dooropen', value: 'faDoorOpen'},
		{label: 'dotcircle', value: 'faDotCircle'},
		{label: 'dove', value: 'faDove'},
		{label: 'downleftanduprighttocenter', value: 'faDownLeftAndUpRightToCenter'},
		{label: 'download', value: 'faDownload'},
		{label: 'downlong', value: 'faDownLong'},
		{label: 'draftingcompass', value: 'faDraftingCompass'},
		{label: 'dragon', value: 'faDragon'},
		{label: 'drawpolygon', value: 'faDrawPolygon'},
		{label: 'driverslicense', value: 'faDriversLicense'},
		{label: 'droplet', value: 'faDroplet'},
		{label: 'dropletslash', value: 'faDropletSlash'},
		{label: 'drum', value: 'faDrum'},
		{label: 'drumsteelpan', value: 'faDrumSteelpan'},
		{label: 'drumstickbite', value: 'faDrumstickBite'},
		{label: 'dumbbell', value: 'faDumbbell'},
		{label: 'dumpster', value: 'faDumpster'},
		{label: 'dumpsterfire', value: 'faDumpsterFire'},
		{label: 'dungeon', value: 'faDungeon'},
		{label: 'e', value: 'faE'},
		{label: 'eardeaf', value: 'faEarDeaf'},
		{label: 'earlisten', value: 'faEarListen'},
		{label: 'earth', value: 'faEarth'},
		{label: 'earthafrica', value: 'faEarthAfrica'},
		{label: 'earthamerica', value: 'faEarthAmerica'},
		{label: 'earthamericas', value: 'faEarthAmericas'},
		{label: 'earthasia', value: 'faEarthAsia'},
		{label: 'eartheurope', value: 'faEarthEurope'},
		{label: 'earthoceania', value: 'faEarthOceania'},
		{label: 'edit', value: 'faEdit'},
		{label: 'egg', value: 'faEgg'},
		{label: 'eject', value: 'faEject'},
		{label: 'elevator', value: 'faElevator'},
		{label: 'ellipsis', value: 'faEllipsis'},
		{label: 'ellipsish', value: 'faEllipsisH'},
		{label: 'ellipsisv', value: 'faEllipsisV'},
		{label: 'ellipsisvertical', value: 'faEllipsisVertical'},
		{label: 'envelope', value: 'faEnvelope'},
		{label: 'envelopecirclecheck', value: 'faEnvelopeCircleCheck'},
		{label: 'envelopeopen', value: 'faEnvelopeOpen'},
		{label: 'envelopeopentext', value: 'faEnvelopeOpenText'},
		{label: 'envelopesbulk', value: 'faEnvelopesBulk'},
		{label: 'envelopesquare', value: 'faEnvelopeSquare'},
		{label: 'equals', value: 'faEquals'},
		{label: 'eraser', value: 'faEraser'},
		{label: 'ethernet', value: 'faEthernet'},
		{label: 'eur', value: 'faEur'},
		{label: 'euro', value: 'faEuro'},
		{label: 'eurosign', value: 'faEuroSign'},
		{label: 'exchange', value: 'faExchange'},
		{label: 'exchangealt', value: 'faExchangeAlt'},
		{label: 'exclamation', value: 'faExclamation'},
		{label: 'exclamationcircle', value: 'faExclamationCircle'},
		{label: 'exclamationtriangle', value: 'faExclamationTriangle'},
		{label: 'expand', value: 'faExpand'},
		{label: 'expandalt', value: 'faExpandAlt'},
		{label: 'expandarrowsalt', value: 'faExpandArrowsAlt'},
		{label: 'explosion', value: 'faExplosion'},
		{label: 'externallink', value: 'faExternalLink'},
		{label: 'externallinkalt', value: 'faExternalLinkAlt'},
		{label: 'externallinksquare', value: 'faExternalLinkSquare'},
		{label: 'externallinksquarealt', value: 'faExternalLinkSquareAlt'},
		{label: 'eye', value: 'faEye'},
		{label: 'eyedropper', value: 'faEyeDropper'},
		{label: 'eyedropper', value: 'faEyedropper'},
		{label: 'eyedropperempty', value: 'faEyeDropperEmpty'},
		{label: 'eyelowvision', value: 'faEyeLowVision'},
		{label: 'eyeslash', value: 'faEyeSlash'},
		{label: 'f', value: 'faF'},
		{label: 'faceangry', value: 'faFaceAngry'},
		{label: 'facedizzy', value: 'faFaceDizzy'},
		{label: 'faceflushed', value: 'faFaceFlushed'},
		{label: 'facefrown', value: 'faFaceFrown'},
		{label: 'facefrownopen', value: 'faFaceFrownOpen'},
		{label: 'facegrimace', value: 'faFaceGrimace'},
		{label: 'facegrin', value: 'faFaceGrin'},
		{label: 'facegrinbeam', value: 'faFaceGrinBeam'},
		{label: 'facegrinbeamsweat', value: 'faFaceGrinBeamSweat'},
		{label: 'facegrinhearts', value: 'faFaceGrinHearts'},
		{label: 'facegrinsquint', value: 'faFaceGrinSquint'},
		{label: 'facegrinsquinttears', value: 'faFaceGrinSquintTears'},
		{label: 'facegrinstars', value: 'faFaceGrinStars'},
		{label: 'facegrintears', value: 'faFaceGrinTears'},
		{label: 'facegrintongue', value: 'faFaceGrinTongue'},
		{label: 'facegrintonguesquint', value: 'faFaceGrinTongueSquint'},
		{label: 'facegrintonguewink', value: 'faFaceGrinTongueWink'},
		{label: 'facegrinwide', value: 'faFaceGrinWide'},
		{label: 'facegrinwink', value: 'faFaceGrinWink'},
		{label: 'facekiss', value: 'faFaceKiss'},
		{label: 'facekissbeam', value: 'faFaceKissBeam'},
		{label: 'facekisswinkheart', value: 'faFaceKissWinkHeart'},
		{label: 'facelaugh', value: 'faFaceLaugh'},
		{label: 'facelaughbeam', value: 'faFaceLaughBeam'},
		{label: 'facelaughsquint', value: 'faFaceLaughSquint'},
		{label: 'facelaughwink', value: 'faFaceLaughWink'},
		{label: 'facemeh', value: 'faFaceMeh'},
		{label: 'facemehblank', value: 'faFaceMehBlank'},
		{label: 'facerollingeyes', value: 'faFaceRollingEyes'},
		{label: 'facesadcry', value: 'faFaceSadCry'},
		{label: 'facesadtear', value: 'faFaceSadTear'},
		{label: 'facesmile', value: 'faFaceSmile'},
		{label: 'facesmilebeam', value: 'faFaceSmileBeam'},
		{label: 'facesmilewink', value: 'faFaceSmileWink'},
		{label: 'facesurprise', value: 'faFaceSurprise'},
		{label: 'facetired', value: 'faFaceTired'},
		{label: 'fan', value: 'faFan'},
		{label: 'fastbackward', value: 'faFastBackward'},
		{label: 'fastforward', value: 'faFastForward'},
		{label: 'faucet', value: 'faFaucet'},
		{label: 'faucetdrip', value: 'faFaucetDrip'},
		{label: 'fax', value: 'faFax'},
		{label: 'feather', value: 'faFeather'},
		{label: 'featheralt', value: 'faFeatherAlt'},
		{label: 'featherpointed', value: 'faFeatherPointed'},
		{label: 'feed', value: 'faFeed'},
		{label: 'female', value: 'faFemale'},
		{label: 'ferry', value: 'faFerry'},
		{label: 'fighterjet', value: 'faFighterJet'},
		{label: 'file', value: 'faFile'},
		{label: 'filealt', value: 'faFileAlt'},
		{label: 'filearchive', value: 'faFileArchive'},
		{label: 'filearrowdown', value: 'faFileArrowDown'},
		{label: 'filearrowup', value: 'faFileArrowUp'},
		{label: 'fileaudio', value: 'faFileAudio'},
		{label: 'filecirclecheck', value: 'faFileCircleCheck'},
		{label: 'filecircleexclamation', value: 'faFileCircleExclamation'},
		{label: 'filecircleminus', value: 'faFileCircleMinus'},
		{label: 'filecircleplus', value: 'faFileCirclePlus'},
		{label: 'filecirclequestion', value: 'faFileCircleQuestion'},
		{label: 'filecirclexmark', value: 'faFileCircleXmark'},
		{label: 'fileclipboard', value: 'faFileClipboard'},
		{label: 'filecode', value: 'faFileCode'},
		{label: 'filecontract', value: 'faFileContract'},
		{label: 'filecsv', value: 'faFileCsv'},
		{label: 'filedownload', value: 'faFileDownload'},
		{label: 'fileedit', value: 'faFileEdit'},
		{label: 'fileexcel', value: 'faFileExcel'},
		{label: 'fileexport', value: 'faFileExport'},
		{label: 'filefragment', value: 'faFileFragment'},
		{label: 'filehalfdashed', value: 'faFileHalfDashed'},
		{label: 'fileimage', value: 'faFileImage'},
		{label: 'fileimport', value: 'faFileImport'},
		{label: 'fileinvoice', value: 'faFileInvoice'},
		{label: 'fileinvoicedollar', value: 'faFileInvoiceDollar'},
		{label: 'filelines', value: 'faFileLines'},
		{label: 'filemedical', value: 'faFileMedical'},
		{label: 'filemedicalalt', value: 'faFileMedicalAlt'},
		{label: 'filepdf', value: 'faFilePdf'},
		{label: 'filepen', value: 'faFilePen'},
		{label: 'filepowerpoint', value: 'faFilePowerpoint'},
		{label: 'fileprescription', value: 'faFilePrescription'},
		{label: 'fileshield', value: 'faFileShield'},
		{label: 'filesignature', value: 'faFileSignature'},
		{label: 'filetext', value: 'faFileText'},
		{label: 'fileupload', value: 'faFileUpload'},
		{label: 'filevideo', value: 'faFileVideo'},
		{label: 'filewaveform', value: 'faFileWaveform'},
		{label: 'fileword', value: 'faFileWord'},
		{label: 'filezipper', value: 'faFileZipper'},
		{label: 'fill', value: 'faFill'},
		{label: 'filldrip', value: 'faFillDrip'},
		{label: 'film', value: 'faFilm'},
		{label: 'filter', value: 'faFilter'},
		{label: 'filtercircledollar', value: 'faFilterCircleDollar'},
		{label: 'filtercirclexmark', value: 'faFilterCircleXmark'},
		{label: 'fingerprint', value: 'faFingerprint'},
		{label: 'fire', value: 'faFire'},
		{label: 'firealt', value: 'faFireAlt'},
		{label: 'fireburner', value: 'faFireBurner'},
		{label: 'fireextinguisher', value: 'faFireExtinguisher'},
		{label: 'fireflamecurved', value: 'faFireFlameCurved'},
		{label: 'fireflamesimple', value: 'faFireFlameSimple'},
		{label: 'firstaid', value: 'faFirstAid'},
		{label: 'fish', value: 'faFish'},
		{label: 'fishfins', value: 'faFishFins'},
		{label: 'fistraised', value: 'faFistRaised'},
		{label: 'flag', value: 'faFlag'},
		{label: 'flagcheckered', value: 'faFlagCheckered'},
		{label: 'flagusa', value: 'faFlagUsa'},
		{label: 'flask', value: 'faFlask'},
		{label: 'flaskvial', value: 'faFlaskVial'},
		{label: 'floppydisk', value: 'faFloppyDisk'},
		{label: 'florinsign', value: 'faFlorinSign'},
		{label: 'flushed', value: 'faFlushed'},
		{label: 'folder', value: 'faFolder'},
		{label: 'folderblank', value: 'faFolderBlank'},
		{label: 'folderclosed', value: 'faFolderClosed'},
		{label: 'folderminus', value: 'faFolderMinus'},
		{label: 'folderopen', value: 'faFolderOpen'},
		{label: 'folderplus', value: 'faFolderPlus'},
		{label: 'foldertree', value: 'faFolderTree'},
		{label: 'font', value: 'faFont'},
		{label: 'fontawesome', value: 'faFontAwesome'},
		{label: 'fontawesomeflag', value: 'faFontAwesomeFlag'},
		{label: 'fontawesomelogofull', value: 'faFontAwesomeLogoFull'},
		{label: 'football', value: 'faFootball'},
		{label: 'footballball', value: 'faFootballBall'},
		{label: 'forward', value: 'faForward'},
		{label: 'forwardfast', value: 'faForwardFast'},
		{label: 'forwardstep', value: 'faForwardStep'},
		{label: 'francsign', value: 'faFrancSign'},
		{label: 'frog', value: 'faFrog'},
		{label: 'frown', value: 'faFrown'},
		{label: 'frownopen', value: 'faFrownOpen'},
		{label: 'funneldollar', value: 'faFunnelDollar'},
		{label: 'futbol', value: 'faFutbol'},
		{label: 'futbolball', value: 'faFutbolBall'},
		{label: 'g', value: 'faG'},
		{label: 'gamepad', value: 'faGamepad'},
		{label: 'gaspump', value: 'faGasPump'},
		{label: 'gauge', value: 'faGauge'},
		{label: 'gaugehigh', value: 'faGaugeHigh'},
		{label: 'gaugemed', value: 'faGaugeMed'},
		{label: 'gaugesimple', value: 'faGaugeSimple'},
		{label: 'gaugesimplehigh', value: 'faGaugeSimpleHigh'},
		{label: 'gaugesimplemed', value: 'faGaugeSimpleMed'},
		{label: 'gavel', value: 'faGavel'},
		{label: 'gbp', value: 'faGbp'},
		{label: 'gear', value: 'faGear'},
		{label: 'gears', value: 'faGears'},
		{label: 'gem', value: 'faGem'},
		{label: 'genderless', value: 'faGenderless'},
		{label: 'ghost', value: 'faGhost'},
		{label: 'gift', value: 'faGift'},
		{label: 'gifts', value: 'faGifts'},
		{label: 'glasscheers', value: 'faGlassCheers'},
		{label: 'glasses', value: 'faGlasses'},
		{label: 'glassmartini', value: 'faGlassMartini'},
		{label: 'glassmartinialt', value: 'faGlassMartiniAlt'},
		{label: 'glasswater', value: 'faGlassWater'},
		{label: 'glasswaterdroplet', value: 'faGlassWaterDroplet'},
		{label: 'glasswhiskey', value: 'faGlassWhiskey'},
		{label: 'globe', value: 'faGlobe'},
		{label: 'globeafrica', value: 'faGlobeAfrica'},
		{label: 'globeamericas', value: 'faGlobeAmericas'},
		{label: 'globeasia', value: 'faGlobeAsia'},
		{label: 'globeeurope', value: 'faGlobeEurope'},
		{label: 'globeoceania', value: 'faGlobeOceania'},
		{label: 'golfball', value: 'faGolfBall'},
		{label: 'golfballtee', value: 'faGolfBallTee'},
		{label: 'gopuram', value: 'faGopuram'},
		{label: 'graduationcap', value: 'faGraduationCap'},
		{label: 'greaterthan', value: 'faGreaterThan'},
		{label: 'greaterthanequal', value: 'faGreaterThanEqual'},
		{label: 'grimace', value: 'faGrimace'},
		{label: 'grin', value: 'faGrin'},
		{label: 'grinalt', value: 'faGrinAlt'},
		{label: 'grinbeam', value: 'faGrinBeam'},
		{label: 'grinbeamsweat', value: 'faGrinBeamSweat'},
		{label: 'grinhearts', value: 'faGrinHearts'},
		{label: 'grinsquint', value: 'faGrinSquint'},
		{label: 'grinsquinttears', value: 'faGrinSquintTears'},
		{label: 'grinstars', value: 'faGrinStars'},
		{label: 'grintears', value: 'faGrinTears'},
		{label: 'grintongue', value: 'faGrinTongue'},
		{label: 'grintonguesquint', value: 'faGrinTongueSquint'},
		{label: 'grintonguewink', value: 'faGrinTongueWink'},
		{label: 'grinwink', value: 'faGrinWink'},
		{label: 'grip', value: 'faGrip'},
		{label: 'griphorizontal', value: 'faGripHorizontal'},
		{label: 'griplines', value: 'faGripLines'},
		{label: 'griplinesvertical', value: 'faGripLinesVertical'},
		{label: 'gripvertical', value: 'faGripVertical'},
		{label: 'grouparrowsrotate', value: 'faGroupArrowsRotate'},
		{label: 'guaranisign', value: 'faGuaraniSign'},
		{label: 'guitar', value: 'faGuitar'},
		{label: 'gun', value: 'faGun'},
		{label: 'h', value: 'faH'},
		{label: 'hamburger', value: 'faHamburger'},
		{label: 'hammer', value: 'faHammer'},
		{label: 'hamsa', value: 'faHamsa'},
		{label: 'hand', value: 'faHand'},
		{label: 'handbackfist', value: 'faHandBackFist'},
		{label: 'handcuffs', value: 'faHandcuffs'},
		{label: 'handdots', value: 'faHandDots'},
		{label: 'handfist', value: 'faHandFist'},
		{label: 'handholding', value: 'faHandHolding'},
		{label: 'handholdingdollar', value: 'faHandHoldingDollar'},
		{label: 'handholdingdroplet', value: 'faHandHoldingDroplet'},
		{label: 'handholdinghand', value: 'faHandHoldingHand'},
		{label: 'handholdingheart', value: 'faHandHoldingHeart'},
		{label: 'handholdingmedical', value: 'faHandHoldingMedical'},
		{label: 'handholdingusd', value: 'faHandHoldingUsd'},
		{label: 'handholdingwater', value: 'faHandHoldingWater'},
		{label: 'handlizard', value: 'faHandLizard'},
		{label: 'handmiddlefinger', value: 'faHandMiddleFinger'},
		{label: 'handpaper', value: 'faHandPaper'},
		{label: 'handpeace', value: 'faHandPeace'},
		{label: 'handpointdown', value: 'faHandPointDown'},
		{label: 'handpointer', value: 'faHandPointer'},
		{label: 'handpointleft', value: 'faHandPointLeft'},
		{label: 'handpointright', value: 'faHandPointRight'},
		{label: 'handpointup', value: 'faHandPointUp'},
		{label: 'handrock', value: 'faHandRock'},
		{label: 'hands', value: 'faHands'},
		{
			label: 'handsamericansignlanguageinterpreting',
			value: 'faHandsAmericanSignLanguageInterpreting',
		},
		{label: 'handsaslinterpreting', value: 'faHandsAslInterpreting'},
		{label: 'handsbound', value: 'faHandsBound'},
		{label: 'handsbubbles', value: 'faHandsBubbles'},
		{label: 'handscissors', value: 'faHandScissors'},
		{label: 'handsclapping', value: 'faHandsClapping'},
		{label: 'handshake', value: 'faHandshake'},
		{label: 'handshakealt', value: 'faHandshakeAlt'},
		{label: 'handshakealtslash', value: 'faHandshakeAltSlash'},
		{label: 'handshakeangle', value: 'faHandshakeAngle'},
		{label: 'handshakesimple', value: 'faHandshakeSimple'},
		{label: 'handshakesimpleslash', value: 'faHandshakeSimpleSlash'},
		{label: 'handshakeslash', value: 'faHandshakeSlash'},
		{label: 'handshelping', value: 'faHandsHelping'},
		{label: 'handsholding', value: 'faHandsHolding'},
		{label: 'handsholdingchild', value: 'faHandsHoldingChild'},
		{label: 'handsholdingcircle', value: 'faHandsHoldingCircle'},
		{label: 'handsparkles', value: 'faHandSparkles'},
		{label: 'handspock', value: 'faHandSpock'},
		{label: 'handspraying', value: 'faHandsPraying'},
		{label: 'handswash', value: 'faHandsWash'},
		{label: 'hanukiah', value: 'faHanukiah'},
		{label: 'harddrive', value: 'faHardDrive'},
		{label: 'hardhat', value: 'faHardHat'},
		{label: 'hardofhearing', value: 'faHardOfHearing'},
		{label: 'hashtag', value: 'faHashtag'},
		{label: 'hatcowboy', value: 'faHatCowboy'},
		{label: 'hatcowboyside', value: 'faHatCowboySide'},
		{label: 'hathard', value: 'faHatHard'},
		{label: 'hatwizard', value: 'faHatWizard'},
		{label: 'haykal', value: 'faHaykal'},
		{label: 'hdd', value: 'faHdd'},
		{label: 'header', value: 'faHeader'},
		{label: 'heading', value: 'faHeading'},
		{label: 'headphones', value: 'faHeadphones'},
		{label: 'headphonesalt', value: 'faHeadphonesAlt'},
		{label: 'headphonessimple', value: 'faHeadphonesSimple'},
		{label: 'headset', value: 'faHeadset'},
		{label: 'headsidecough', value: 'faHeadSideCough'},
		{label: 'headsidecoughslash', value: 'faHeadSideCoughSlash'},
		{label: 'headsidemask', value: 'faHeadSideMask'},
		{label: 'headsidevirus', value: 'faHeadSideVirus'},
		{label: 'heart', value: 'faHeart'},
		{label: 'heartbeat', value: 'faHeartbeat'},
		{label: 'heartbroken', value: 'faHeartBroken'},
		{label: 'heartcirclebolt', value: 'faHeartCircleBolt'},
		{label: 'heartcirclecheck', value: 'faHeartCircleCheck'},
		{label: 'heartcircleexclamation', value: 'faHeartCircleExclamation'},
		{label: 'heartcircleminus', value: 'faHeartCircleMinus'},
		{label: 'heartcircleplus', value: 'faHeartCirclePlus'},
		{label: 'heartcirclexmark', value: 'faHeartCircleXmark'},
		{label: 'heartcrack', value: 'faHeartCrack'},
		{label: 'heartmusiccamerabolt', value: 'faHeartMusicCameraBolt'},
		{label: 'heartpulse', value: 'faHeartPulse'},
		{label: 'helicopter', value: 'faHelicopter'},
		{label: 'helicoptersymbol', value: 'faHelicopterSymbol'},
		{label: 'helmetsafety', value: 'faHelmetSafety'},
		{label: 'helmetun', value: 'faHelmetUn'},
		{label: 'hexagonnodes', value: 'faHexagonNodes'},
		{label: 'hexagonnodesbolt', value: 'faHexagonNodesBolt'},
		{label: 'highlighter', value: 'faHighlighter'},
		{label: 'hiking', value: 'faHiking'},
		{label: 'hillavalanche', value: 'faHillAvalanche'},
		{label: 'hillrockslide', value: 'faHillRockslide'},
		{label: 'hippo', value: 'faHippo'},
		{label: 'history', value: 'faHistory'},
		{label: 'hockeypuck', value: 'faHockeyPuck'},
		{label: 'hollyberry', value: 'faHollyBerry'},
		{label: 'home', value: 'faHome'},
		{label: 'homealt', value: 'faHomeAlt'},
		{label: 'homelg', value: 'faHomeLg'},
		{label: 'homelgalt', value: 'faHomeLgAlt'},
		{label: 'homeuser', value: 'faHomeUser'},
		{label: 'horse', value: 'faHorse'},
		{label: 'horsehead', value: 'faHorseHead'},
		{label: 'hospital', value: 'faHospital'},
		{label: 'hospitalalt', value: 'faHospitalAlt'},
		{label: 'hospitalsymbol', value: 'faHospitalSymbol'},
		{label: 'hospitaluser', value: 'faHospitalUser'},
		{label: 'hospitalwide', value: 'faHospitalWide'},
		{label: 'hotdog', value: 'faHotdog'},
		{label: 'hotel', value: 'faHotel'},
		{label: 'hottub', value: 'faHotTub'},
		{label: 'hottubperson', value: 'faHotTubPerson'},
		{label: 'hourglass', value: 'faHourglass'},
		{label: 'hourglass1', value: 'faHourglass1'},
		{label: 'hourglass2', value: 'faHourglass2'},
		{label: 'hourglass3', value: 'faHourglass3'},
		{label: 'hourglassempty', value: 'faHourglassEmpty'},
		{label: 'hourglassend', value: 'faHourglassEnd'},
		{label: 'hourglasshalf', value: 'faHourglassHalf'},
		{label: 'hourglassstart', value: 'faHourglassStart'},
		{label: 'house', value: 'faHouse'},
		{label: 'housechimney', value: 'faHouseChimney'},
		{label: 'housechimneycrack', value: 'faHouseChimneyCrack'},
		{label: 'housechimneymedical', value: 'faHouseChimneyMedical'},
		{label: 'housechimneyuser', value: 'faHouseChimneyUser'},
		{label: 'housechimneywindow', value: 'faHouseChimneyWindow'},
		{label: 'housecirclecheck', value: 'faHouseCircleCheck'},
		{label: 'housecircleexclamation', value: 'faHouseCircleExclamation'},
		{label: 'housecirclexmark', value: 'faHouseCircleXmark'},
		{label: 'housecrack', value: 'faHouseCrack'},
		{label: 'housedamage', value: 'faHouseDamage'},
		{label: 'housefire', value: 'faHouseFire'},
		{label: 'houseflag', value: 'faHouseFlag'},
		{label: 'housefloodwater', value: 'faHouseFloodWater'},
		{label: 'housefloodwatercirclearrowright', value: 'faHouseFloodWaterCircleArrowRight'},
		{label: 'houselaptop', value: 'faHouseLaptop'},
		{label: 'houselock', value: 'faHouseLock'},
		{label: 'housemedical', value: 'faHouseMedical'},
		{label: 'housemedicalcirclecheck', value: 'faHouseMedicalCircleCheck'},
		{label: 'housemedicalcircleexclamation', value: 'faHouseMedicalCircleExclamation'},
		{label: 'housemedicalcirclexmark', value: 'faHouseMedicalCircleXmark'},
		{label: 'housemedicalflag', value: 'faHouseMedicalFlag'},
		{label: 'housesignal', value: 'faHouseSignal'},
		{label: 'housetsunami', value: 'faHouseTsunami'},
		{label: 'houseuser', value: 'faHouseUser'},
		{label: 'hryvnia', value: 'faHryvnia'},
		{label: 'hryvniasign', value: 'faHryvniaSign'},
		{label: 'hsquare', value: 'faHSquare'},
		{label: 'hurricane', value: 'faHurricane'},
		{label: 'i', value: 'faI'},
		{label: 'icecream', value: 'faIceCream'},
		{label: 'icicles', value: 'faIcicles'},
		{label: 'icons', value: 'faIcons'},
		{label: 'icursor', value: 'faICursor'},
		{label: 'idbadge', value: 'faIdBadge'},
		{label: 'idcard', value: 'faIdCard'},
		{label: 'idcardalt', value: 'faIdCardAlt'},
		{label: 'idcardclip', value: 'faIdCardClip'},
		{label: 'igloo', value: 'faIgloo'},
		{label: 'ils', value: 'faIls'},
		{label: 'image', value: 'faImage'},
		{label: 'imageportrait', value: 'faImagePortrait'},
		{label: 'images', value: 'faImages'},
		{label: 'inbox', value: 'faInbox'},
		{label: 'indent', value: 'faIndent'},
		{label: 'indianrupee', value: 'faIndianRupee'},
		{label: 'indianrupeesign', value: 'faIndianRupeeSign'},
		{label: 'industry', value: 'faIndustry'},
		{label: 'infinity', value: 'faInfinity'},
		{label: 'info', value: 'faInfo'},
		{label: 'infocircle', value: 'faInfoCircle'},
		{label: 'inr', value: 'faInr'},
		{label: 'institution', value: 'faInstitution'},
		{label: 'italic', value: 'faItalic'},
		{label: 'j', value: 'faJ'},
		{label: 'jar', value: 'faJar'},
		{label: 'jarwheat', value: 'faJarWheat'},
		{label: 'jedi', value: 'faJedi'},
		{label: 'jetfighter', value: 'faJetFighter'},
		{label: 'jetfighterup', value: 'faJetFighterUp'},
		{label: 'joint', value: 'faJoint'},
		{label: 'journalwhills', value: 'faJournalWhills'},
		{label: 'jpy', value: 'faJpy'},
		{label: 'jugdetergent', value: 'faJugDetergent'},
		{label: 'k', value: 'faK'},
		{label: 'kaaba', value: 'faKaaba'},
		{label: 'key', value: 'faKey'},
		{label: 'keyboard', value: 'faKeyboard'},
		{label: 'khanda', value: 'faKhanda'},
		{label: 'kipsign', value: 'faKipSign'},
		{label: 'kiss', value: 'faKiss'},
		{label: 'kissbeam', value: 'faKissBeam'},
		{label: 'kisswinkheart', value: 'faKissWinkHeart'},
		{label: 'kitchenset', value: 'faKitchenSet'},
		{label: 'kitmedical', value: 'faKitMedical'},
		{label: 'kiwibird', value: 'faKiwiBird'},
		{label: 'krw', value: 'faKrw'},
		{label: 'l', value: 'faL'},
		{label: 'ladderwater', value: 'faLadderWater'},
		{label: 'landmark', value: 'faLandmark'},
		{label: 'landmarkalt', value: 'faLandmarkAlt'},
		{label: 'landmarkdome', value: 'faLandmarkDome'},
		{label: 'landmarkflag', value: 'faLandmarkFlag'},
		{label: 'landmineon', value: 'faLandMineOn'},
		{label: 'language', value: 'faLanguage'},
		{label: 'laptop', value: 'faLaptop'},
		{label: 'laptopcode', value: 'faLaptopCode'},
		{label: 'laptopfile', value: 'faLaptopFile'},
		{label: 'laptophouse', value: 'faLaptopHouse'},
		{label: 'laptopmedical', value: 'faLaptopMedical'},
		{label: 'larisign', value: 'faLariSign'},
		{label: 'laugh', value: 'faLaugh'},
		{label: 'laughbeam', value: 'faLaughBeam'},
		{label: 'laughsquint', value: 'faLaughSquint'},
		{label: 'laughwink', value: 'faLaughWink'},
		{label: 'layergroup', value: 'faLayerGroup'},
		{label: 'leaf', value: 'faLeaf'},
		{label: 'leftlong', value: 'faLeftLong'},
		{label: 'leftright', value: 'faLeftRight'},
		{label: 'legal', value: 'faLegal'},
		{label: 'lemon', value: 'faLemon'},
		{label: 'lessthan', value: 'faLessThan'},
		{label: 'lessthanequal', value: 'faLessThanEqual'},
		{label: 'leveldown', value: 'faLevelDown'},
		{label: 'leveldownalt', value: 'faLevelDownAlt'},
		{label: 'levelup', value: 'faLevelUp'},
		{label: 'levelupalt', value: 'faLevelUpAlt'},
		{label: 'lifering', value: 'faLifeRing'},
		{label: 'lightbulb', value: 'faLightbulb'},
		{label: 'linechart', value: 'faLineChart'},
		{label: 'linesleaning', value: 'faLinesLeaning'},
		{label: 'link', value: 'faLink'},
		{label: 'linkslash', value: 'faLinkSlash'},
		{label: 'lirasign', value: 'faLiraSign'},
		{label: 'list', value: 'faList'},
		{label: 'list12', value: 'faList12'},
		{label: 'listalt', value: 'faListAlt'},
		{label: 'listcheck', value: 'faListCheck'},
		{label: 'listdots', value: 'faListDots'},
		{label: 'listnumeric', value: 'faListNumeric'},
		{label: 'listol', value: 'faListOl'},
		{label: 'listsquares', value: 'faListSquares'},
		{label: 'listul', value: 'faListUl'},
		{label: 'litecoinsign', value: 'faLitecoinSign'},
		{label: 'location', value: 'faLocation'},
		{label: 'locationarrow', value: 'faLocationArrow'},
		{label: 'locationcrosshairs', value: 'faLocationCrosshairs'},
		{label: 'locationdot', value: 'faLocationDot'},
		{label: 'locationpin', value: 'faLocationPin'},
		{label: 'locationpinlock', value: 'faLocationPinLock'},
		{label: 'lock', value: 'faLock'},
		{label: 'lockopen', value: 'faLockOpen'},
		{label: 'locust', value: 'faLocust'},
		{label: 'longarrowaltdown', value: 'faLongArrowAltDown'},
		{label: 'longarrowaltleft', value: 'faLongArrowAltLeft'},
		{label: 'longarrowaltright', value: 'faLongArrowAltRight'},
		{label: 'longarrowaltup', value: 'faLongArrowAltUp'},
		{label: 'longarrowdown', value: 'faLongArrowDown'},
		{label: 'longarrowleft', value: 'faLongArrowLeft'},
		{label: 'longarrowright', value: 'faLongArrowRight'},
		{label: 'longarrowup', value: 'faLongArrowUp'},
		{label: 'lowvision', value: 'faLowVision'},
		{label: 'luggagecart', value: 'faLuggageCart'},
		{label: 'lungs', value: 'faLungs'},
		{label: 'lungsvirus', value: 'faLungsVirus'},
		{label: 'm', value: 'faM'},
		{label: 'magic', value: 'faMagic'},
		{label: 'magicwandsparkles', value: 'faMagicWandSparkles'},
		{label: 'magnet', value: 'faMagnet'},
		{label: 'magnifyingglass', value: 'faMagnifyingGlass'},
		{label: 'magnifyingglassarrowright', value: 'faMagnifyingGlassArrowRight'},
		{label: 'magnifyingglasschart', value: 'faMagnifyingGlassChart'},
		{label: 'magnifyingglassdollar', value: 'faMagnifyingGlassDollar'},
		{label: 'magnifyingglasslocation', value: 'faMagnifyingGlassLocation'},
		{label: 'magnifyingglassminus', value: 'faMagnifyingGlassMinus'},
		{label: 'magnifyingglassplus', value: 'faMagnifyingGlassPlus'},
		{label: 'mailbulk', value: 'faMailBulk'},
		{label: 'mailforward', value: 'faMailForward'},
		{label: 'mailreply', value: 'faMailReply'},
		{label: 'mailreplyall', value: 'faMailReplyAll'},
		{label: 'male', value: 'faMale'},
		{label: 'manatsign', value: 'faManatSign'},
		{label: 'map', value: 'faMap'},
		{label: 'maplocation', value: 'faMapLocation'},
		{label: 'maplocationdot', value: 'faMapLocationDot'},
		{label: 'mapmarked', value: 'faMapMarked'},
		{label: 'mapmarkedalt', value: 'faMapMarkedAlt'},
		{label: 'mapmarker', value: 'faMapMarker'},
		{label: 'mapmarkeralt', value: 'faMapMarkerAlt'},
		{label: 'mappin', value: 'faMapPin'},
		{label: 'mapsigns', value: 'faMapSigns'},
		{label: 'marker', value: 'faMarker'},
		{label: 'mars', value: 'faMars'},
		{label: 'marsandvenus', value: 'faMarsAndVenus'},
		{label: 'marsandvenusburst', value: 'faMarsAndVenusBurst'},
		{label: 'marsdouble', value: 'faMarsDouble'},
		{label: 'marsstroke', value: 'faMarsStroke'},
		{label: 'marsstrokeh', value: 'faMarsStrokeH'},
		{label: 'marsstrokeright', value: 'faMarsStrokeRight'},
		{label: 'marsstrokeup', value: 'faMarsStrokeUp'},
		{label: 'marsstrokev', value: 'faMarsStrokeV'},
		{label: 'martiniglass', value: 'faMartiniGlass'},
		{label: 'martiniglasscitrus', value: 'faMartiniGlassCitrus'},
		{label: 'martiniglassempty', value: 'faMartiniGlassEmpty'},
		{label: 'mask', value: 'faMask'},
		{label: 'maskface', value: 'faMaskFace'},
		{label: 'maskstheater', value: 'faMasksTheater'},
		{label: 'maskventilator', value: 'faMaskVentilator'},
		{label: 'mattresspillow', value: 'faMattressPillow'},
		{label: 'maximize', value: 'faMaximize'},
		{label: 'medal', value: 'faMedal'},
		{label: 'medkit', value: 'faMedkit'},
		{label: 'meh', value: 'faMeh'},
		{label: 'mehblank', value: 'faMehBlank'},
		{label: 'mehrollingeyes', value: 'faMehRollingEyes'},
		{label: 'memory', value: 'faMemory'},
		{label: 'menorah', value: 'faMenorah'},
		{label: 'mercury', value: 'faMercury'},
		{label: 'message', value: 'faMessage'},
		{label: 'meteor', value: 'faMeteor'},
		{label: 'microchip', value: 'faMicrochip'},
		{label: 'microphone', value: 'faMicrophone'},
		{label: 'microphonealt', value: 'faMicrophoneAlt'},
		{label: 'microphonealtslash', value: 'faMicrophoneAltSlash'},
		{label: 'microphonelines', value: 'faMicrophoneLines'},
		{label: 'microphonelinesslash', value: 'faMicrophoneLinesSlash'},
		{label: 'microphoneslash', value: 'faMicrophoneSlash'},
		{label: 'microscope', value: 'faMicroscope'},
		{label: 'millsign', value: 'faMillSign'},
		{label: 'minimize', value: 'faMinimize'},
		{label: 'minus', value: 'faMinus'},
		{label: 'minuscircle', value: 'faMinusCircle'},
		{label: 'minussquare', value: 'faMinusSquare'},
		{label: 'mitten', value: 'faMitten'},
		{label: 'mobile', value: 'faMobile'},
		{label: 'mobilealt', value: 'faMobileAlt'},
		{label: 'mobileandroid', value: 'faMobileAndroid'},
		{label: 'mobileandroidalt', value: 'faMobileAndroidAlt'},
		{label: 'mobilebutton', value: 'faMobileButton'},
		{label: 'mobilephone', value: 'faMobilePhone'},
		{label: 'mobileretro', value: 'faMobileRetro'},
		{label: 'mobilescreen', value: 'faMobileScreen'},
		{label: 'mobilescreenbutton', value: 'faMobileScreenButton'},
		{label: 'moneybill', value: 'faMoneyBill'},
		{label: 'moneybill1', value: 'faMoneyBill1'},
		{label: 'moneybill1wave', value: 'faMoneyBill1Wave'},
		{label: 'moneybillalt', value: 'faMoneyBillAlt'},
		{label: 'moneybills', value: 'faMoneyBills'},
		{label: 'moneybilltransfer', value: 'faMoneyBillTransfer'},
		{label: 'moneybilltrendup', value: 'faMoneyBillTrendUp'},
		{label: 'moneybillwave', value: 'faMoneyBillWave'},
		{label: 'moneybillwavealt', value: 'faMoneyBillWaveAlt'},
		{label: 'moneybillwheat', value: 'faMoneyBillWheat'},
		{label: 'moneycheck', value: 'faMoneyCheck'},
		{label: 'moneycheckalt', value: 'faMoneyCheckAlt'},
		{label: 'moneycheckdollar', value: 'faMoneyCheckDollar'},
		{label: 'monument', value: 'faMonument'},
		{label: 'moon', value: 'faMoon'},
		{label: 'mortarboard', value: 'faMortarBoard'},
		{label: 'mortarpestle', value: 'faMortarPestle'},
		{label: 'mosque', value: 'faMosque'},
		{label: 'mosquito', value: 'faMosquito'},
		{label: 'mosquitonet', value: 'faMosquitoNet'},
		{label: 'motorcycle', value: 'faMotorcycle'},
		{label: 'mound', value: 'faMound'},
		{label: 'mountain', value: 'faMountain'},
		{label: 'mountaincity', value: 'faMountainCity'},
		{label: 'mountainsun', value: 'faMountainSun'},
		{label: 'mouse', value: 'faMouse'},
		{label: 'mousepointer', value: 'faMousePointer'},
		{label: 'mughot', value: 'faMugHot'},
		{label: 'mugsaucer', value: 'faMugSaucer'},
		{label: 'multiply', value: 'faMultiply'},
		{label: 'museum', value: 'faMuseum'},
		{label: 'music', value: 'faMusic'},
		{label: 'n', value: 'faN'},
		{label: 'nairasign', value: 'faNairaSign'},
		{label: 'navicon', value: 'faNavicon'},
		{label: 'networkwired', value: 'faNetworkWired'},
		{label: 'neuter', value: 'faNeuter'},
		{label: 'newspaper', value: 'faNewspaper'},
		{label: 'notdef', value: 'faNotdef'},
		{label: 'notequal', value: 'faNotEqual'},
		{label: 'notesmedical', value: 'faNotesMedical'},
		{label: 'notesticky', value: 'faNoteSticky'},
		{label: 'o', value: 'faO'},
		{label: 'objectgroup', value: 'faObjectGroup'},
		{label: 'objectungroup', value: 'faObjectUngroup'},
		{label: 'oilcan', value: 'faOilCan'},
		{label: 'oilwell', value: 'faOilWell'},
		{label: 'om', value: 'faOm'},
		{label: 'otter', value: 'faOtter'},
		{label: 'outdent', value: 'faOutdent'},
		{label: 'p', value: 'faP'},
		{label: 'pager', value: 'faPager'},
		{label: 'paintbrush', value: 'faPaintbrush'},
		{label: 'paintbrush', value: 'faPaintBrush'},
		{label: 'paintroller', value: 'faPaintRoller'},
		{label: 'palette', value: 'faPalette'},
		{label: 'pallet', value: 'faPallet'},
		{label: 'panorama', value: 'faPanorama'},
		{label: 'paperclip', value: 'faPaperclip'},
		{label: 'paperplane', value: 'faPaperPlane'},
		{label: 'parachutebox', value: 'faParachuteBox'},
		{label: 'paragraph', value: 'faParagraph'},
		{label: 'parking', value: 'faParking'},
		{label: 'passport', value: 'faPassport'},
		{label: 'pastafarianism', value: 'faPastafarianism'},
		{label: 'paste', value: 'faPaste'},
		{label: 'pause', value: 'faPause'},
		{label: 'pausecircle', value: 'faPauseCircle'},
		{label: 'paw', value: 'faPaw'},
		{label: 'peace', value: 'faPeace'},
		{label: 'pen', value: 'faPen'},
		{label: 'penalt', value: 'faPenAlt'},
		{label: 'pencil', value: 'faPencil'},
		{label: 'pencilalt', value: 'faPencilAlt'},
		{label: 'pencilruler', value: 'faPencilRuler'},
		{label: 'pencilsquare', value: 'faPencilSquare'},
		{label: 'penclip', value: 'faPenClip'},
		{label: 'penfancy', value: 'faPenFancy'},
		{label: 'pennib', value: 'faPenNib'},
		{label: 'penruler', value: 'faPenRuler'},
		{label: 'pensquare', value: 'faPenSquare'},
		{label: 'pentosquare', value: 'faPenToSquare'},
		{label: 'peoplearrows', value: 'faPeopleArrows'},
		{label: 'peoplearrowsleftright', value: 'faPeopleArrowsLeftRight'},
		{label: 'peoplecarry', value: 'faPeopleCarry'},
		{label: 'peoplecarrybox', value: 'faPeopleCarryBox'},
		{label: 'peoplegroup', value: 'faPeopleGroup'},
		{label: 'peopleline', value: 'faPeopleLine'},
		{label: 'peoplepulling', value: 'faPeoplePulling'},
		{label: 'peoplerobbery', value: 'faPeopleRobbery'},
		{label: 'peopleroof', value: 'faPeopleRoof'},
		{label: 'pepperhot', value: 'faPepperHot'},
		{label: 'percent', value: 'faPercent'},
		{label: 'percentage', value: 'faPercentage'},
		{label: 'person', value: 'faPerson'},
		{label: 'personarrowdowntoline', value: 'faPersonArrowDownToLine'},
		{label: 'personarrowupfromline', value: 'faPersonArrowUpFromLine'},
		{label: 'personbiking', value: 'faPersonBiking'},
		{label: 'personbooth', value: 'faPersonBooth'},
		{label: 'personbreastfeeding', value: 'faPersonBreastfeeding'},
		{label: 'personburst', value: 'faPersonBurst'},
		{label: 'personcane', value: 'faPersonCane'},
		{label: 'personchalkboard', value: 'faPersonChalkboard'},
		{label: 'personcirclecheck', value: 'faPersonCircleCheck'},
		{label: 'personcircleexclamation', value: 'faPersonCircleExclamation'},
		{label: 'personcircleminus', value: 'faPersonCircleMinus'},
		{label: 'personcircleplus', value: 'faPersonCirclePlus'},
		{label: 'personcirclequestion', value: 'faPersonCircleQuestion'},
		{label: 'personcirclexmark', value: 'faPersonCircleXmark'},
		{label: 'persondigging', value: 'faPersonDigging'},
		{label: 'persondotsfromline', value: 'faPersonDotsFromLine'},
		{label: 'persondress', value: 'faPersonDress'},
		{label: 'persondressburst', value: 'faPersonDressBurst'},
		{label: 'persondrowning', value: 'faPersonDrowning'},
		{label: 'personfalling', value: 'faPersonFalling'},
		{label: 'personfallingburst', value: 'faPersonFallingBurst'},
		{label: 'personhalfdress', value: 'faPersonHalfDress'},
		{label: 'personharassing', value: 'faPersonHarassing'},
		{label: 'personhiking', value: 'faPersonHiking'},
		{label: 'personmilitarypointing', value: 'faPersonMilitaryPointing'},
		{label: 'personmilitaryrifle', value: 'faPersonMilitaryRifle'},
		{label: 'personmilitarytoperson', value: 'faPersonMilitaryToPerson'},
		{label: 'personpraying', value: 'faPersonPraying'},
		{label: 'personpregnant', value: 'faPersonPregnant'},
		{label: 'personrays', value: 'faPersonRays'},
		{label: 'personrifle', value: 'faPersonRifle'},
		{label: 'personrunning', value: 'faPersonRunning'},
		{label: 'personshelter', value: 'faPersonShelter'},
		{label: 'personskating', value: 'faPersonSkating'},
		{label: 'personskiing', value: 'faPersonSkiing'},
		{label: 'personskiingnordic', value: 'faPersonSkiingNordic'},
		{label: 'personsnowboarding', value: 'faPersonSnowboarding'},
		{label: 'personswimming', value: 'faPersonSwimming'},
		{label: 'personthroughwindow', value: 'faPersonThroughWindow'},
		{label: 'personwalking', value: 'faPersonWalking'},
		{label: 'personwalkingarrowloopleft', value: 'faPersonWalkingArrowLoopLeft'},
		{label: 'personwalkingarrowright', value: 'faPersonWalkingArrowRight'},
		{label: 'personwalkingdashedlinearrowright', value: 'faPersonWalkingDashedLineArrowRight'},
		{label: 'personwalkingluggage', value: 'faPersonWalkingLuggage'},
		{label: 'personwalkingwithcane', value: 'faPersonWalkingWithCane'},
		{label: 'pesetasign', value: 'faPesetaSign'},
		{label: 'pesosign', value: 'faPesoSign'},
		{label: 'phone', value: 'faPhone'},
		{label: 'phonealt', value: 'faPhoneAlt'},
		{label: 'phoneflip', value: 'faPhoneFlip'},
		{label: 'phoneslash', value: 'faPhoneSlash'},
		{label: 'phonesquare', value: 'faPhoneSquare'},
		{label: 'phonesquarealt', value: 'faPhoneSquareAlt'},
		{label: 'phonevolume', value: 'faPhoneVolume'},
		{label: 'photofilm', value: 'faPhotoFilm'},
		{label: 'photovideo', value: 'faPhotoVideo'},
		{label: 'piechart', value: 'faPieChart'},
		{label: 'piggybank', value: 'faPiggyBank'},
		{label: 'pills', value: 'faPills'},
		{label: 'pingpongpaddleball', value: 'faPingPongPaddleBall'},
		{label: 'pizzaslice', value: 'faPizzaSlice'},
		{label: 'placeofworship', value: 'faPlaceOfWorship'},
		{label: 'plane', value: 'faPlane'},
		{label: 'planearrival', value: 'faPlaneArrival'},
		{label: 'planecirclecheck', value: 'faPlaneCircleCheck'},
		{label: 'planecircleexclamation', value: 'faPlaneCircleExclamation'},
		{label: 'planecirclexmark', value: 'faPlaneCircleXmark'},
		{label: 'planedeparture', value: 'faPlaneDeparture'},
		{label: 'planelock', value: 'faPlaneLock'},
		{label: 'planeslash', value: 'faPlaneSlash'},
		{label: 'planeup', value: 'faPlaneUp'},
		{label: 'plantwilt', value: 'faPlantWilt'},
		{label: 'platewheat', value: 'faPlateWheat'},
		{label: 'play', value: 'faPlay'},
		{label: 'playcircle', value: 'faPlayCircle'},
		{label: 'plug', value: 'faPlug'},
		{label: 'plugcirclebolt', value: 'faPlugCircleBolt'},
		{label: 'plugcirclecheck', value: 'faPlugCircleCheck'},
		{label: 'plugcircleexclamation', value: 'faPlugCircleExclamation'},
		{label: 'plugcircleminus', value: 'faPlugCircleMinus'},
		{label: 'plugcircleplus', value: 'faPlugCirclePlus'},
		{label: 'plugcirclexmark', value: 'faPlugCircleXmark'},
		{label: 'plus', value: 'faPlus'},
		{label: 'pluscircle', value: 'faPlusCircle'},
		{label: 'plusminus', value: 'faPlusMinus'},
		{label: 'plussquare', value: 'faPlusSquare'},
		{label: 'podcast', value: 'faPodcast'},
		{label: 'poll', value: 'faPoll'},
		{label: 'pollh', value: 'faPollH'},
		{label: 'poo', value: 'faPoo'},
		{label: 'poobolt', value: 'faPooBolt'},
		{label: 'poop', value: 'faPoop'},
		{label: 'poostorm', value: 'faPooStorm'},
		{label: 'portrait', value: 'faPortrait'},
		{label: 'poundsign', value: 'faPoundSign'},
		{label: 'poweroff', value: 'faPowerOff'},
		{label: 'pray', value: 'faPray'},
		{label: 'prayinghands', value: 'faPrayingHands'},
		{label: 'prescription', value: 'faPrescription'},
		{label: 'prescriptionbottle', value: 'faPrescriptionBottle'},
		{label: 'prescriptionbottlealt', value: 'faPrescriptionBottleAlt'},
		{label: 'prescriptionbottlemedical', value: 'faPrescriptionBottleMedical'},
		{label: 'print', value: 'faPrint'},
		{label: 'procedures', value: 'faProcedures'},
		{label: 'projectdiagram', value: 'faProjectDiagram'},
		{label: 'pumpmedical', value: 'faPumpMedical'},
		{label: 'pumpsoap', value: 'faPumpSoap'},
		{label: 'puzzlepiece', value: 'faPuzzlePiece'},
		{label: 'q', value: 'faQ'},
		{label: 'qrcode', value: 'faQrcode'},
		{label: 'question', value: 'faQuestion'},
		{label: 'questioncircle', value: 'faQuestionCircle'},
		{label: 'quidditch', value: 'faQuidditch'},
		{label: 'quidditchbroomball', value: 'faQuidditchBroomBall'},
		{label: 'quoteleft', value: 'faQuoteLeft'},
		{label: 'quoteleftalt', value: 'faQuoteLeftAlt'},
		{label: 'quoteright', value: 'faQuoteRight'},
		{label: 'quoterightalt', value: 'faQuoteRightAlt'},
		{label: 'quran', value: 'faQuran'},
		{label: 'r', value: 'faR'},
		{label: 'radiation', value: 'faRadiation'},
		{label: 'radiationalt', value: 'faRadiationAlt'},
		{label: 'radio', value: 'faRadio'},
		{label: 'rainbow', value: 'faRainbow'},
		{label: 'random', value: 'faRandom'},
		{label: 'rankingstar', value: 'faRankingStar'},
		{label: 'receipt', value: 'faReceipt'},
		{label: 'recordvinyl', value: 'faRecordVinyl'},
		{label: 'rectanglead', value: 'faRectangleAd'},
		{label: 'rectanglelist', value: 'faRectangleList'},
		{label: 'rectangletimes', value: 'faRectangleTimes'},
		{label: 'rectanglexmark', value: 'faRectangleXmark'},
		{label: 'recycle', value: 'faRecycle'},
		{label: 'redo', value: 'faRedo'},
		{label: 'redoalt', value: 'faRedoAlt'},
		{label: 'refresh', value: 'faRefresh'},
		{label: 'registered', value: 'faRegistered'},
		{label: 'remove', value: 'faRemove'},
		{label: 'removeformat', value: 'faRemoveFormat'},
		{label: 'reorder', value: 'faReorder'},
		{label: 'repeat', value: 'faRepeat'},
		{label: 'reply', value: 'faReply'},
		{label: 'replyall', value: 'faReplyAll'},
		{label: 'republican', value: 'faRepublican'},
		{label: 'restroom', value: 'faRestroom'},
		{label: 'retweet', value: 'faRetweet'},
		{label: 'ribbon', value: 'faRibbon'},
		{label: 'rightfrombracket', value: 'faRightFromBracket'},
		{label: 'rightleft', value: 'faRightLeft'},
		{label: 'rightlong', value: 'faRightLong'},
		{label: 'righttobracket', value: 'faRightToBracket'},
		{label: 'ring', value: 'faRing'},
		{label: 'rmb', value: 'faRmb'},
		{label: 'road', value: 'faRoad'},
		{label: 'roadbarrier', value: 'faRoadBarrier'},
		{label: 'roadbridge', value: 'faRoadBridge'},
		{label: 'roadcirclecheck', value: 'faRoadCircleCheck'},
		{label: 'roadcircleexclamation', value: 'faRoadCircleExclamation'},
		{label: 'roadcirclexmark', value: 'faRoadCircleXmark'},
		{label: 'roadlock', value: 'faRoadLock'},
		{label: 'roadspikes', value: 'faRoadSpikes'},
		{label: 'robot', value: 'faRobot'},
		{label: 'rocket', value: 'faRocket'},
		{label: 'rodasclepius', value: 'faRodAsclepius'},
		{label: 'rodsnake', value: 'faRodSnake'},
		{label: 'rotate', value: 'faRotate'},
		{label: 'rotateback', value: 'faRotateBack'},
		{label: 'rotatebackward', value: 'faRotateBackward'},
		{label: 'rotateforward', value: 'faRotateForward'},
		{label: 'rotateleft', value: 'faRotateLeft'},
		{label: 'rotateright', value: 'faRotateRight'},
		{label: 'rouble', value: 'faRouble'},
		{label: 'route', value: 'faRoute'},
		{label: 'rss', value: 'faRss'},
		{label: 'rsssquare', value: 'faRssSquare'},
		{label: 'rub', value: 'faRub'},
		{label: 'ruble', value: 'faRuble'},
		{label: 'rublesign', value: 'faRubleSign'},
		{label: 'rug', value: 'faRug'},
		{label: 'ruler', value: 'faRuler'},
		{label: 'rulercombined', value: 'faRulerCombined'},
		{label: 'rulerhorizontal', value: 'faRulerHorizontal'},
		{label: 'rulervertical', value: 'faRulerVertical'},
		{label: 'running', value: 'faRunning'},
		{label: 'rupee', value: 'faRupee'},
		{label: 'rupeesign', value: 'faRupeeSign'},
		{label: 'rupiahsign', value: 'faRupiahSign'},
		{label: 's', value: 'faS'},
		{label: 'sackdollar', value: 'faSackDollar'},
		{label: 'sackxmark', value: 'faSackXmark'},
		{label: 'sadcry', value: 'faSadCry'},
		{label: 'sadtear', value: 'faSadTear'},
		{label: 'sailboat', value: 'faSailboat'},
		{label: 'satellite', value: 'faSatellite'},
		{label: 'satellitedish', value: 'faSatelliteDish'},
		{label: 'save', value: 'faSave'},
		{label: 'scalebalanced', value: 'faScaleBalanced'},
		{label: 'scaleunbalanced', value: 'faScaleUnbalanced'},
		{label: 'scaleunbalancedflip', value: 'faScaleUnbalancedFlip'},
		{label: 'school', value: 'faSchool'},
		{label: 'schoolcirclecheck', value: 'faSchoolCircleCheck'},
		{label: 'schoolcircleexclamation', value: 'faSchoolCircleExclamation'},
		{label: 'schoolcirclexmark', value: 'faSchoolCircleXmark'},
		{label: 'schoolflag', value: 'faSchoolFlag'},
		{label: 'schoollock', value: 'faSchoolLock'},
		{label: 'scissors', value: 'faScissors'},
		{label: 'screwdriver', value: 'faScrewdriver'},
		{label: 'screwdriverwrench', value: 'faScrewdriverWrench'},
		{label: 'scroll', value: 'faScroll'},
		{label: 'scrolltorah', value: 'faScrollTorah'},
		{label: 'sdcard', value: 'faSdCard'},
		{label: 'search', value: 'faSearch'},
		{label: 'searchdollar', value: 'faSearchDollar'},
		{label: 'searchlocation', value: 'faSearchLocation'},
		{label: 'searchminus', value: 'faSearchMinus'},
		{label: 'searchplus', value: 'faSearchPlus'},
		{label: 'section', value: 'faSection'},
		{label: 'seedling', value: 'faSeedling'},
		{label: 'server', value: 'faServer'},
		{label: 'shapes', value: 'faShapes'},
		{label: 'share', value: 'faShare'},
		{label: 'sharealt', value: 'faShareAlt'},
		{label: 'sharealtsquare', value: 'faShareAltSquare'},
		{label: 'sharefromsquare', value: 'faShareFromSquare'},
		{label: 'sharenodes', value: 'faShareNodes'},
		{label: 'sharesquare', value: 'faShareSquare'},
		{label: 'sheetplastic', value: 'faSheetPlastic'},
		{label: 'shekel', value: 'faShekel'},
		{label: 'shekelsign', value: 'faShekelSign'},
		{label: 'sheqel', value: 'faSheqel'},
		{label: 'sheqelsign', value: 'faSheqelSign'},
		{label: 'shield', value: 'faShield'},
		{label: 'shieldalt', value: 'faShieldAlt'},
		{label: 'shieldblank', value: 'faShieldBlank'},
		{label: 'shieldcat', value: 'faShieldCat'},
		{label: 'shielddog', value: 'faShieldDog'},
		{label: 'shieldhalved', value: 'faShieldHalved'},
		{label: 'shieldheart', value: 'faShieldHeart'},
		{label: 'shieldvirus', value: 'faShieldVirus'},
		{label: 'ship', value: 'faShip'},
		{label: 'shippingfast', value: 'faShippingFast'},
		{label: 'shirt', value: 'faShirt'},
		{label: 'shoeprints', value: 'faShoePrints'},
		{label: 'shop', value: 'faShop'},
		{label: 'shoplock', value: 'faShopLock'},
		{label: 'shoppingbag', value: 'faShoppingBag'},
		{label: 'shoppingbasket', value: 'faShoppingBasket'},
		{label: 'shoppingcart', value: 'faShoppingCart'},
		{label: 'shopslash', value: 'faShopSlash'},
		{label: 'shower', value: 'faShower'},
		{label: 'shrimp', value: 'faShrimp'},
		{label: 'shuffle', value: 'faShuffle'},
		{label: 'shuttlespace', value: 'faShuttleSpace'},
		{label: 'shuttlevan', value: 'faShuttleVan'},
		{label: 'sign', value: 'faSign'},
		{label: 'signal', value: 'faSignal'},
		{label: 'signal5', value: 'faSignal5'},
		{label: 'signalperfect', value: 'faSignalPerfect'},
		{label: 'signature', value: 'faSignature'},
		{label: 'signhanging', value: 'faSignHanging'},
		{label: 'signin', value: 'faSignIn'},
		{label: 'signinalt', value: 'faSignInAlt'},
		{label: 'signing', value: 'faSigning'},
		{label: 'signlanguage', value: 'faSignLanguage'},
		{label: 'signout', value: 'faSignOut'},
		{label: 'signoutalt', value: 'faSignOutAlt'},
		{label: 'signspost', value: 'faSignsPost'},
		{label: 'simcard', value: 'faSimCard'},
		{label: 'sink', value: 'faSink'},
		{label: 'sitemap', value: 'faSitemap'},
		{label: 'skating', value: 'faSkating'},
		{label: 'skiing', value: 'faSkiing'},
		{label: 'skiingnordic', value: 'faSkiingNordic'},
		{label: 'skull', value: 'faSkull'},
		{label: 'skullcrossbones', value: 'faSkullCrossbones'},
		{label: 'slash', value: 'faSlash'},
		{label: 'sleigh', value: 'faSleigh'},
		{label: 'sliders', value: 'faSliders'},
		{label: 'slidersh', value: 'faSlidersH'},
		{label: 'smile', value: 'faSmile'},
		{label: 'smilebeam', value: 'faSmileBeam'},
		{label: 'smilewink', value: 'faSmileWink'},
		{label: 'smog', value: 'faSmog'},
		{label: 'smoking', value: 'faSmoking'},
		{label: 'smokingban', value: 'faSmokingBan'},
		{label: 'sms', value: 'faSms'},
		{label: 'snowboarding', value: 'faSnowboarding'},
		{label: 'snowflake', value: 'faSnowflake'},
		{label: 'snowman', value: 'faSnowman'},
		{label: 'snowplow', value: 'faSnowplow'},
		{label: 'soap', value: 'faSoap'},
		{label: 'soccerball', value: 'faSoccerBall'},
		{label: 'socks', value: 'faSocks'},
		{label: 'solarpanel', value: 'faSolarPanel'},
		{label: 'sort', value: 'faSort'},
		{label: 'sortalphaasc', value: 'faSortAlphaAsc'},
		{label: 'sortalphadesc', value: 'faSortAlphaDesc'},
		{label: 'sortalphadown', value: 'faSortAlphaDown'},
		{label: 'sortalphadownalt', value: 'faSortAlphaDownAlt'},
		{label: 'sortalphaup', value: 'faSortAlphaUp'},
		{label: 'sortalphaupalt', value: 'faSortAlphaUpAlt'},
		{label: 'sortamountasc', value: 'faSortAmountAsc'},
		{label: 'sortamountdesc', value: 'faSortAmountDesc'},
		{label: 'sortamountdown', value: 'faSortAmountDown'},
		{label: 'sortamountdownalt', value: 'faSortAmountDownAlt'},
		{label: 'sortamountup', value: 'faSortAmountUp'},
		{label: 'sortamountupalt', value: 'faSortAmountUpAlt'},
		{label: 'sortasc', value: 'faSortAsc'},
		{label: 'sortdesc', value: 'faSortDesc'},
		{label: 'sortdown', value: 'faSortDown'},
		{label: 'sortnumericasc', value: 'faSortNumericAsc'},
		{label: 'sortnumericdesc', value: 'faSortNumericDesc'},
		{label: 'sortnumericdown', value: 'faSortNumericDown'},
		{label: 'sortnumericdownalt', value: 'faSortNumericDownAlt'},
		{label: 'sortnumericup', value: 'faSortNumericUp'},
		{label: 'sortnumericupalt', value: 'faSortNumericUpAlt'},
		{label: 'sortup', value: 'faSortUp'},
		{label: 'spa', value: 'faSpa'},
		{label: 'spaceshuttle', value: 'faSpaceShuttle'},
		{label: 'spaghettimonsterflying', value: 'faSpaghettiMonsterFlying'},
		{label: 'spellcheck', value: 'faSpellCheck'},
		{label: 'spider', value: 'faSpider'},
		{label: 'spinner', value: 'faSpinner'},
		{label: 'splotch', value: 'faSplotch'},
		{label: 'spoon', value: 'faSpoon'},
		{label: 'spraycan', value: 'faSprayCan'},
		{label: 'spraycansparkles', value: 'faSprayCanSparkles'},
		{label: 'sprout', value: 'faSprout'},
		{label: 'square', value: 'faSquare'},
		{label: 'squarearrowupright', value: 'faSquareArrowUpRight'},
		{label: 'squarebinary', value: 'faSquareBinary'},
		{label: 'squarecaretdown', value: 'faSquareCaretDown'},
		{label: 'squarecaretleft', value: 'faSquareCaretLeft'},
		{label: 'squarecaretright', value: 'faSquareCaretRight'},
		{label: 'squarecaretup', value: 'faSquareCaretUp'},
		{label: 'squarecheck', value: 'faSquareCheck'},
		{label: 'squareenvelope', value: 'faSquareEnvelope'},
		{label: 'squarefull', value: 'faSquareFull'},
		{label: 'squareh', value: 'faSquareH'},
		{label: 'squareminus', value: 'faSquareMinus'},
		{label: 'squarenfi', value: 'faSquareNfi'},
		{label: 'squareparking', value: 'faSquareParking'},
		{label: 'squarepen', value: 'faSquarePen'},
		{label: 'squarepersonconfined', value: 'faSquarePersonConfined'},
		{label: 'squarephone', value: 'faSquarePhone'},
		{label: 'squarephoneflip', value: 'faSquarePhoneFlip'},
		{label: 'squareplus', value: 'faSquarePlus'},
		{label: 'squarepollhorizontal', value: 'faSquarePollHorizontal'},
		{label: 'squarepollvertical', value: 'faSquarePollVertical'},
		{label: 'squarerootalt', value: 'faSquareRootAlt'},
		{label: 'squarerootvariable', value: 'faSquareRootVariable'},
		{label: 'squarerss', value: 'faSquareRss'},
		{label: 'squaresharenodes', value: 'faSquareShareNodes'},
		{label: 'squareupright', value: 'faSquareUpRight'},
		{label: 'squarevirus', value: 'faSquareVirus'},
		{label: 'squarexmark', value: 'faSquareXmark'},
		{label: 'staffaesculapius', value: 'faStaffAesculapius'},
		{label: 'staffsnake', value: 'faStaffSnake'},
		{label: 'stairs', value: 'faStairs'},
		{label: 'stamp', value: 'faStamp'},
		{label: 'stapler', value: 'faStapler'},
		{label: 'star', value: 'faStar'},
		{label: 'starandcrescent', value: 'faStarAndCrescent'},
		{label: 'starhalf', value: 'faStarHalf'},
		{label: 'starhalfalt', value: 'faStarHalfAlt'},
		{label: 'starhalfstroke', value: 'faStarHalfStroke'},
		{label: 'starofdavid', value: 'faStarOfDavid'},
		{label: 'staroflife', value: 'faStarOfLife'},
		{label: 'stepbackward', value: 'faStepBackward'},
		{label: 'stepforward', value: 'faStepForward'},
		{label: 'sterlingsign', value: 'faSterlingSign'},
		{label: 'stethoscope', value: 'faStethoscope'},
		{label: 'stickynote', value: 'faStickyNote'},
		{label: 'stop', value: 'faStop'},
		{label: 'stopcircle', value: 'faStopCircle'},
		{label: 'stopwatch', value: 'faStopwatch'},
		{label: 'stopwatch20', value: 'faStopwatch20'},
		{label: 'store', value: 'faStore'},
		{label: 'storealt', value: 'faStoreAlt'},
		{label: 'storealtslash', value: 'faStoreAltSlash'},
		{label: 'storeslash', value: 'faStoreSlash'},
		{label: 'stream', value: 'faStream'},
		{label: 'streetview', value: 'faStreetView'},
		{label: 'strikethrough', value: 'faStrikethrough'},
		{label: 'stroopwafel', value: 'faStroopwafel'},
		{label: 'subscript', value: 'faSubscript'},
		{label: 'subtract', value: 'faSubtract'},
		{label: 'subway', value: 'faSubway'},
		{label: 'suitcase', value: 'faSuitcase'},
		{label: 'suitcasemedical', value: 'faSuitcaseMedical'},
		{label: 'suitcaserolling', value: 'faSuitcaseRolling'},
		{label: 'sun', value: 'faSun'},
		{label: 'sunplantwilt', value: 'faSunPlantWilt'},
		{label: 'superscript', value: 'faSuperscript'},
		{label: 'surprise', value: 'faSurprise'},
		{label: 'swatchbook', value: 'faSwatchbook'},
		{label: 'swimmer', value: 'faSwimmer'},
		{label: 'swimmingpool', value: 'faSwimmingPool'},
		{label: 'synagogue', value: 'faSynagogue'},
		{label: 'sync', value: 'faSync'},
		{label: 'syncalt', value: 'faSyncAlt'},
		{label: 'syringe', value: 'faSyringe'},
		{label: 't', value: 'faT'},
		{label: 'table', value: 'faTable'},
		{label: 'tablecells', value: 'faTableCells'},
		{label: 'tablecellscolumnlock', value: 'faTableCellsColumnLock'},
		{label: 'tablecellslarge', value: 'faTableCellsLarge'},
		{label: 'tablecellsrowlock', value: 'faTableCellsRowLock'},
		{label: 'tablecellsrowunlock', value: 'faTableCellsRowUnlock'},
		{label: 'tablecolumns', value: 'faTableColumns'},
		{label: 'tablelist', value: 'faTableList'},
		{label: 'tablet', value: 'faTablet'},
		{label: 'tabletalt', value: 'faTabletAlt'},
		{label: 'tabletandroid', value: 'faTabletAndroid'},
		{label: 'tabletbutton', value: 'faTabletButton'},
		{label: 'tabletennis', value: 'faTableTennis'},
		{label: 'tabletennispaddleball', value: 'faTableTennisPaddleBall'},
		{label: 'tablets', value: 'faTablets'},
		{label: 'tabletscreenbutton', value: 'faTabletScreenButton'},
		{label: 'tachographdigital', value: 'faTachographDigital'},
		{label: 'tachometer', value: 'faTachometer'},
		{label: 'tachometeralt', value: 'faTachometerAlt'},
		{label: 'tachometeraltaverage', value: 'faTachometerAltAverage'},
		{label: 'tachometeraltfast', value: 'faTachometerAltFast'},
		{label: 'tachometeraverage', value: 'faTachometerAverage'},
		{label: 'tachometerfast', value: 'faTachometerFast'},
		{label: 'tag', value: 'faTag'},
		{label: 'tags', value: 'faTags'},
		{label: 'tanakh', value: 'faTanakh'},
		{label: 'tape', value: 'faTape'},
		{label: 'tarp', value: 'faTarp'},
		{label: 'tarpdroplet', value: 'faTarpDroplet'},
		{label: 'tasks', value: 'faTasks'},
		{label: 'tasksalt', value: 'faTasksAlt'},
		{label: 'taxi', value: 'faTaxi'},
		{label: 'teeth', value: 'faTeeth'},
		{label: 'teethopen', value: 'faTeethOpen'},
		{label: 'teletype', value: 'faTeletype'},
		{label: 'television', value: 'faTelevision'},
		{label: 'temperature0', value: 'faTemperature0'},
		{label: 'temperature1', value: 'faTemperature1'},
		{label: 'temperature2', value: 'faTemperature2'},
		{label: 'temperature3', value: 'faTemperature3'},
		{label: 'temperature4', value: 'faTemperature4'},
		{label: 'temperaturearrowdown', value: 'faTemperatureArrowDown'},
		{label: 'temperaturearrowup', value: 'faTemperatureArrowUp'},
		{label: 'temperaturedown', value: 'faTemperatureDown'},
		{label: 'temperatureempty', value: 'faTemperatureEmpty'},
		{label: 'temperaturefull', value: 'faTemperatureFull'},
		{label: 'temperaturehalf', value: 'faTemperatureHalf'},
		{label: 'temperaturehigh', value: 'faTemperatureHigh'},
		{label: 'temperaturelow', value: 'faTemperatureLow'},
		{label: 'temperaturequarter', value: 'faTemperatureQuarter'},
		{label: 'temperaturethreequarters', value: 'faTemperatureThreeQuarters'},
		{label: 'temperatureup', value: 'faTemperatureUp'},
		{label: 'tenge', value: 'faTenge'},
		{label: 'tengesign', value: 'faTengeSign'},
		{label: 'tent', value: 'faTent'},
		{label: 'tentarrowdowntoline', value: 'faTentArrowDownToLine'},
		{label: 'tentarrowleftright', value: 'faTentArrowLeftRight'},
		{label: 'tentarrowsdown', value: 'faTentArrowsDown'},
		{label: 'tentarrowturnleft', value: 'faTentArrowTurnLeft'},
		{label: 'tents', value: 'faTents'},
		{label: 'terminal', value: 'faTerminal'},
		{label: 'textheight', value: 'faTextHeight'},
		{label: 'textslash', value: 'faTextSlash'},
		{label: 'textwidth', value: 'faTextWidth'},
		{label: 'th', value: 'faTh'},
		{label: 'theatermasks', value: 'faTheaterMasks'},
		{label: 'thermometer', value: 'faThermometer'},
		{label: 'thermometer0', value: 'faThermometer0'},
		{label: 'thermometer1', value: 'faThermometer1'},
		{label: 'thermometer2', value: 'faThermometer2'},
		{label: 'thermometer3', value: 'faThermometer3'},
		{label: 'thermometer4', value: 'faThermometer4'},
		{label: 'thermometerempty', value: 'faThermometerEmpty'},
		{label: 'thermometerfull', value: 'faThermometerFull'},
		{label: 'thermometerhalf', value: 'faThermometerHalf'},
		{label: 'thermometerquarter', value: 'faThermometerQuarter'},
		{label: 'thermometerthreequarters', value: 'faThermometerThreeQuarters'},
		{label: 'thlarge', value: 'faThLarge'},
		{label: 'thlist', value: 'faThList'},
		{label: 'thumbsdown', value: 'faThumbsDown'},
		{label: 'thumbsup', value: 'faThumbsUp'},
		{label: 'thumbtack', value: 'faThumbtack'},
		{label: 'thumbtack', value: 'faThumbTack'},
		{label: 'thumbtackslash', value: 'faThumbtackSlash'},
		{label: 'thumbtackslash', value: 'faThumbTackSlash'},
		{label: 'thunderstorm', value: 'faThunderstorm'},
		{label: 'ticket', value: 'faTicket'},
		{label: 'ticketalt', value: 'faTicketAlt'},
		{label: 'ticketsimple', value: 'faTicketSimple'},
		{label: 'timeline', value: 'faTimeline'},
		{label: 'times', value: 'faTimes'},
		{label: 'timescircle', value: 'faTimesCircle'},
		{label: 'timesrectangle', value: 'faTimesRectangle'},
		{label: 'timessquare', value: 'faTimesSquare'},
		{label: 'tint', value: 'faTint'},
		{label: 'tintslash', value: 'faTintSlash'},
		{label: 'tired', value: 'faTired'},
		{label: 'toggleoff', value: 'faToggleOff'},
		{label: 'toggleon', value: 'faToggleOn'},
		{label: 'toilet', value: 'faToilet'},
		{label: 'toiletpaper', value: 'faToiletPaper'},
		{label: 'toiletpaperslash', value: 'faToiletPaperSlash'},
		{label: 'toiletportable', value: 'faToiletPortable'},
		{label: 'toiletsportable', value: 'faToiletsPortable'},
		{label: 'toolbox', value: 'faToolbox'},
		{label: 'tools', value: 'faTools'},
		{label: 'tooth', value: 'faTooth'},
		{label: 'torah', value: 'faTorah'},
		{label: 'toriigate', value: 'faToriiGate'},
		{label: 'tornado', value: 'faTornado'},
		{label: 'towerbroadcast', value: 'faTowerBroadcast'},
		{label: 'towercell', value: 'faTowerCell'},
		{label: 'towerobservation', value: 'faTowerObservation'},
		{label: 'tractor', value: 'faTractor'},
		{label: 'trademark', value: 'faTrademark'},
		{label: 'trafficlight', value: 'faTrafficLight'},
		{label: 'trailer', value: 'faTrailer'},
		{label: 'train', value: 'faTrain'},
		{label: 'trainsubway', value: 'faTrainSubway'},
		{label: 'traintram', value: 'faTrainTram'},
		{label: 'tram', value: 'faTram'},
		{label: 'transgender', value: 'faTransgender'},
		{label: 'transgenderalt', value: 'faTransgenderAlt'},
		{label: 'trash', value: 'faTrash'},
		{label: 'trashalt', value: 'faTrashAlt'},
		{label: 'trasharrowup', value: 'faTrashArrowUp'},
		{label: 'trashcan', value: 'faTrashCan'},
		{label: 'trashcanarrowup', value: 'faTrashCanArrowUp'},
		{label: 'trashrestore', value: 'faTrashRestore'},
		{label: 'trashrestorealt', value: 'faTrashRestoreAlt'},
		{label: 'tree', value: 'faTree'},
		{label: 'treecity', value: 'faTreeCity'},
		{label: 'trianglecirclesquare', value: 'faTriangleCircleSquare'},
		{label: 'triangleexclamation', value: 'faTriangleExclamation'},
		{label: 'trophy', value: 'faTrophy'},
		{label: 'trowel', value: 'faTrowel'},
		{label: 'trowelbricks', value: 'faTrowelBricks'},
		{label: 'truck', value: 'faTruck'},
		{label: 'truckarrowright', value: 'faTruckArrowRight'},
		{label: 'truckdroplet', value: 'faTruckDroplet'},
		{label: 'truckfast', value: 'faTruckFast'},
		{label: 'truckfield', value: 'faTruckField'},
		{label: 'truckfieldun', value: 'faTruckFieldUn'},
		{label: 'truckfront', value: 'faTruckFront'},
		{label: 'truckloading', value: 'faTruckLoading'},
		{label: 'truckmedical', value: 'faTruckMedical'},
		{label: 'truckmonster', value: 'faTruckMonster'},
		{label: 'truckmoving', value: 'faTruckMoving'},
		{label: 'truckpickup', value: 'faTruckPickup'},
		{label: 'truckplane', value: 'faTruckPlane'},
		{label: 'truckrampbox', value: 'faTruckRampBox'},
		{label: 'try', value: 'faTry'},
		{label: 'tshirt', value: 'faTShirt'},
		{label: 'tshirt', value: 'faTshirt'},
		{label: 'tty', value: 'faTty'},
		{label: 'turkishlira', value: 'faTurkishLira'},
		{label: 'turkishlirasign', value: 'faTurkishLiraSign'},
		{label: 'turndown', value: 'faTurnDown'},
		{label: 'turnup', value: 'faTurnUp'},
		{label: 'tv', value: 'faTv'},
		{label: 'tvalt', value: 'faTvAlt'},
		{label: 'u', value: 'faU'},
		{label: 'umbrella', value: 'faUmbrella'},
		{label: 'umbrellabeach', value: 'faUmbrellaBeach'},
		{label: 'underline', value: 'faUnderline'},
		{label: 'undo', value: 'faUndo'},
		{label: 'undoalt', value: 'faUndoAlt'},
		{label: 'universalaccess', value: 'faUniversalAccess'},
		{label: 'university', value: 'faUniversity'},
		{label: 'unlink', value: 'faUnlink'},
		{label: 'unlock', value: 'faUnlock'},
		{label: 'unlockalt', value: 'faUnlockAlt'},
		{label: 'unlockkeyhole', value: 'faUnlockKeyhole'},
		{label: 'unsorted', value: 'faUnsorted'},
		{label: 'updown', value: 'faUpDown'},
		{label: 'updownleftright', value: 'faUpDownLeftRight'},
		{label: 'upload', value: 'faUpload'},
		{label: 'uplong', value: 'faUpLong'},
		{label: 'uprightanddownleftfromcenter', value: 'faUpRightAndDownLeftFromCenter'},
		{label: 'uprightfromsquare', value: 'faUpRightFromSquare'},
		{label: 'usd', value: 'faUsd'},
		{label: 'user', value: 'faUser'},
		{label: 'useralt', value: 'faUserAlt'},
		{label: 'useraltslash', value: 'faUserAltSlash'},
		{label: 'userastronaut', value: 'faUserAstronaut'},
		{label: 'usercheck', value: 'faUserCheck'},
		{label: 'usercircle', value: 'faUserCircle'},
		{label: 'userclock', value: 'faUserClock'},
		{label: 'usercog', value: 'faUserCog'},
		{label: 'userdoctor', value: 'faUserDoctor'},
		{label: 'useredit', value: 'faUserEdit'},
		{label: 'userfriends', value: 'faUserFriends'},
		{label: 'usergear', value: 'faUserGear'},
		{label: 'usergraduate', value: 'faUserGraduate'},
		{label: 'usergroup', value: 'faUserGroup'},
		{label: 'userinjured', value: 'faUserInjured'},
		{label: 'userlarge', value: 'faUserLarge'},
		{label: 'userlargeslash', value: 'faUserLargeSlash'},
		{label: 'userlock', value: 'faUserLock'},
		{label: 'usermd', value: 'faUserMd'},
		{label: 'userminus', value: 'faUserMinus'},
		{label: 'userninja', value: 'faUserNinja'},
		{label: 'usernurse', value: 'faUserNurse'},
		{label: 'userpen', value: 'faUserPen'},
		{label: 'userplus', value: 'faUserPlus'},
		{label: 'users', value: 'faUsers'},
		{label: 'usersbetweenlines', value: 'faUsersBetweenLines'},
		{label: 'userscog', value: 'faUsersCog'},
		{label: 'usersecret', value: 'faUserSecret'},
		{label: 'usersgear', value: 'faUsersGear'},
		{label: 'usershield', value: 'faUserShield'},
		{label: 'userslash', value: 'faUserSlash'},
		{label: 'usersline', value: 'faUsersLine'},
		{label: 'usersrays', value: 'faUsersRays'},
		{label: 'usersrectangle', value: 'faUsersRectangle'},
		{label: 'usersslash', value: 'faUsersSlash'},
		{label: 'usersviewfinder', value: 'faUsersViewfinder'},
		{label: 'usertag', value: 'faUserTag'},
		{label: 'usertie', value: 'faUserTie'},
		{label: 'usertimes', value: 'faUserTimes'},
		{label: 'userxmark', value: 'faUserXmark'},
		{label: 'utensils', value: 'faUtensils'},
		{label: 'utensilspoon', value: 'faUtensilSpoon'},
		{label: 'v', value: 'faV'},
		{label: 'vanshuttle', value: 'faVanShuttle'},
		{label: 'vault', value: 'faVault'},
		{label: 'vcard', value: 'faVcard'},
		{label: 'vectorsquare', value: 'faVectorSquare'},
		{label: 'venus', value: 'faVenus'},
		{label: 'venusdouble', value: 'faVenusDouble'},
		{label: 'venusmars', value: 'faVenusMars'},
		{label: 'vest', value: 'faVest'},
		{label: 'vestpatches', value: 'faVestPatches'},
		{label: 'vial', value: 'faVial'},
		{label: 'vialcirclecheck', value: 'faVialCircleCheck'},
		{label: 'vials', value: 'faVials'},
		{label: 'vialvirus', value: 'faVialVirus'},
		{label: 'video', value: 'faVideo'},
		{label: 'videocamera', value: 'faVideoCamera'},
		{label: 'videoslash', value: 'faVideoSlash'},
		{label: 'vihara', value: 'faVihara'},
		{label: 'virus', value: 'faVirus'},
		{label: 'viruscovid', value: 'faVirusCovid'},
		{label: 'viruscovidslash', value: 'faVirusCovidSlash'},
		{label: 'viruses', value: 'faViruses'},
		{label: 'virusslash', value: 'faVirusSlash'},
		{label: 'voicemail', value: 'faVoicemail'},
		{label: 'volcano', value: 'faVolcano'},
		{label: 'volleyball', value: 'faVolleyball'},
		{label: 'volleyballball', value: 'faVolleyballBall'},
		{label: 'volumecontrolphone', value: 'faVolumeControlPhone'},
		{label: 'volumedown', value: 'faVolumeDown'},
		{label: 'volumehigh', value: 'faVolumeHigh'},
		{label: 'volumelow', value: 'faVolumeLow'},
		{label: 'volumemute', value: 'faVolumeMute'},
		{label: 'volumeoff', value: 'faVolumeOff'},
		{label: 'volumetimes', value: 'faVolumeTimes'},
		{label: 'volumeup', value: 'faVolumeUp'},
		{label: 'volumexmark', value: 'faVolumeXmark'},
		{label: 'voteyea', value: 'faVoteYea'},
		{label: 'vrcardboard', value: 'faVrCardboard'},
		{label: 'w', value: 'faW'},
		{label: 'walkietalkie', value: 'faWalkieTalkie'},
		{label: 'walking', value: 'faWalking'},
		{label: 'wallet', value: 'faWallet'},
		{label: 'wandmagic', value: 'faWandMagic'},
		{label: 'wandmagicsparkles', value: 'faWandMagicSparkles'},
		{label: 'wandsparkles', value: 'faWandSparkles'},
		{label: 'warehouse', value: 'faWarehouse'},
		{label: 'warning', value: 'faWarning'},
		{label: 'water', value: 'faWater'},
		{label: 'waterladder', value: 'faWaterLadder'},
		{label: 'wavesquare', value: 'faWaveSquare'},
		{label: 'webawesome', value: 'faWebAwesome'},
		{label: 'weight', value: 'faWeight'},
		{label: 'weighthanging', value: 'faWeightHanging'},
		{label: 'weightscale', value: 'faWeightScale'},
		{label: 'wheatalt', value: 'faWheatAlt'},
		{label: 'wheatawn', value: 'faWheatAwn'},
		{label: 'wheatawncircleexclamation', value: 'faWheatAwnCircleExclamation'},
		{label: 'wheelchair', value: 'faWheelchair'},
		{label: 'wheelchairalt', value: 'faWheelchairAlt'},
		{label: 'wheelchairmove', value: 'faWheelchairMove'},
		{label: 'whiskeyglass', value: 'faWhiskeyGlass'},
		{label: 'wifi', value: 'faWifi'},
		{label: 'wifi3', value: 'faWifi3'},
		{label: 'wifistrong', value: 'faWifiStrong'},
		{label: 'wind', value: 'faWind'},
		{label: 'windowclose', value: 'faWindowClose'},
		{label: 'windowmaximize', value: 'faWindowMaximize'},
		{label: 'windowminimize', value: 'faWindowMinimize'},
		{label: 'windowrestore', value: 'faWindowRestore'},
		{label: 'winebottle', value: 'faWineBottle'},
		{label: 'wineglass', value: 'faWineGlass'},
		{label: 'wineglassalt', value: 'faWineGlassAlt'},
		{label: 'wineglassempty', value: 'faWineGlassEmpty'},
		{label: 'won', value: 'faWon'},
		{label: 'wonsign', value: 'faWonSign'},
		{label: 'worm', value: 'faWorm'},
		{label: 'wrench', value: 'faWrench'},
		{label: 'x', value: 'faX'},
		{label: 'xmark', value: 'faXmark'},
		{label: 'xmarkcircle', value: 'faXmarkCircle'},
		{label: 'xmarkslines', value: 'faXmarksLines'},
		{label: 'xmarksquare', value: 'faXmarkSquare'},
		{label: 'xray', value: 'faXRay'},
		{label: 'y', value: 'faY'},
		{label: 'yen', value: 'faYen'},
		{label: 'yensign', value: 'faYenSign'},
		{label: 'yinyang', value: 'faYinYang'},
		{label: 'z', value: 'faZ'},
		{label: 'zap', value: 'faZap'},
	];

	return data;
};
