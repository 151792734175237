/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {IWorkFlowItem} from 'src/@types/app';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {workflowIcons, workflowSubcomponent, workflowTitle} from '../helper';
import * as S from '../styles';
import {Input, WidthBox, Wrapper} from 'src/shared/components/UI';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {v4 as uuidV4} from 'uuid';

export const createWorkflowItemInput = (
	item: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
): JSX.Element => {
	let subComponents = workFlowItem.filter(x => x.refItem == item.ref);

	if ([WorkFlowTypeEnum.AlertConfirm].includes(item.type!)) subComponents = [];

	const buildOption = (type: WorkFlowTypeEnum) => (
		<S.OptionItem
			title={workflowTitle(type)}
			onClick={() =>
				changeFlowItem(workFlowItem, setWorkFlowItem, {
					...item,
					type: type,
				})
			}>
			<i className={workflowIcons(type)}></i>
		</S.OptionItem>
	);

	return (
		<Wrapper flexDirection="column" gap="0px">
			<S.Content
				onClick={e => {
					e.stopPropagation();
					changeFlowItem(workFlowItem, setWorkFlowItem, {
						...item,
						showConfig: !item.showConfig,
						showTypes: false,
					});
				}}>
				<Wrapper gap="0px" width="90%">
					<S.IconAction
						onClick={e => {
							e.stopPropagation();
							changeFlowItem(workFlowItem, setWorkFlowItem, {
								...item,
								showTypes: !item.showTypes,
								showConfig: false,
							});
						}}>
						<i className={workflowIcons(item.type ?? WorkFlowTypeEnum.Default)}></i>
					</S.IconAction>
					{item.isEditText ? (
						<WidthBox width="70%">
							<Input
								value={item.text}
								onChange={val =>
									changeFlowItem(workFlowItem, setWorkFlowItem, {
										...item,
										text: val,
									})
								}
								inputType="undeline"
								onPressEnter={() =>
									changeFlowItem(workFlowItem, setWorkFlowItem, {
										...item,
										isEditText: false,
									})
								}
							/>
						</WidthBox>
					) : (
						<S.Text
							onClick={e => e.stopPropagation()}
							onDoubleClick={e => {
								e.stopPropagation();
								changeFlowItem(workFlowItem, setWorkFlowItem, {
									...item,
									isEditText: true,
								});
							}}>
							{item?.text}
						</S.Text>
					)}
				</Wrapper>
				<Wrapper gap="0px" width="10%" justifyContent="end" margin="0 2px 0 0">
					{createOptionsMenu(workFlowItem, setWorkFlowItem, item)}
				</Wrapper>
			</S.Content>
			{item.showConfig ? (
				<S.SubContent>
					<Wrapper margin="7px" gap="7px">
						{workflowSubcomponent(
							item.type ?? WorkFlowTypeEnum.Default,
							item,
							workFlowItem,
							setWorkFlowItem,
						)}
					</Wrapper>
				</S.SubContent>
			) : (
				<></>
			)}
			{item.showTypes ? (
				<S.SubContent>
					<Wrapper margin="7px" gap="7px" flexWrap="wrap">
						{buildOption(WorkFlowTypeEnum.Variable)}
						{buildOption(WorkFlowTypeEnum.AttrVariable)}
						{buildOption(WorkFlowTypeEnum.EnableDisableFields)}
						{buildOption(WorkFlowTypeEnum.ShowHideFields)}
						{buildOption(WorkFlowTypeEnum.Condition)}
						{buildOption(WorkFlowTypeEnum.Http)}
						{buildOption(WorkFlowTypeEnum.Alert)}
						{buildOption(WorkFlowTypeEnum.AlertConfirm)}
						{buildOption(WorkFlowTypeEnum.SetAutocompleteValue)}
						{buildOption(WorkFlowTypeEnum.SetValue)}
					</Wrapper>
				</S.SubContent>
			) : (
				<></>
			)}

			{buildSubComponents(subComponents, workFlowItem, setWorkFlowItem)}
		</Wrapper>
	);
};

const changeFlowItem = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	flowItem: IWorkFlowItem,
	isRemove?: boolean,
) => {
	let workflowData: IWorkFlowItem[];
	if (!isRemove) {
		workflowData = [...workFlowItem].map(item => {
			if (item.ref === flowItem.ref) return flowItem;
			item.showMenu = false;
			return item;
		});

		const finderSub = workFlowItem.filter(x => x.refItem == flowItem.ref);

		if (flowItem.type === WorkFlowTypeEnum.Condition && (!finderSub || finderSub.length == 0)) {
			workflowData.push({
				ref: uuidV4().toString(),
				refItem: flowItem.ref,
				text: 'Se sim',
				type: WorkFlowTypeEnum.IfYes,
			});
			workflowData.push({
				ref: uuidV4().toString(),
				refItem: flowItem.ref,
				text: 'Se não',
				type: WorkFlowTypeEnum.IfNo,
			});
		} else if (workFlowItem.filter(item => item.refItem == flowItem.ref)?.length > 0) {
			workflowData = [...workflowData].filter(item => item.refItem != flowItem.ref);
		}
	} else {
		workflowData = [...workFlowItem].filter(item => item.ref != flowItem.ref);
	}

	setWorkFlowItem(workflowData);
};

const createOptionsMenu = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	item: IWorkFlowItem,
) => {
	return (
		<S.OptionsMenu
			onClick={(e: any) => {
				e.stopPropagation();
				changeFlowItem(workFlowItem, setWorkFlowItem, item, true);
			}}>
			<FontAwesomeIcon icon={faClose} />
		</S.OptionsMenu>
	);
};

const buildSubComponents = (
	subComponents: IWorkFlowItem[] | undefined,
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
) => {
	if (subComponents && subComponents.length > 0) {
		return (
			<S.ContentSubComponent>
				{createSubcomponent(subComponents, workFlowItem, setWorkFlowItem)}
			</S.ContentSubComponent>
		);
	}
};

const createSubcomponent = (
	items: IWorkFlowItem[],
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
) => {
	return items.map((item, index) => {
		return (
			<S.SubComponent key={index}>
				<S.Content
					onClick={e => {
						e.stopPropagation();
						changeFlowItem(workFlowItem, setWorkFlowItem, {
							...item,
							showConfig: !item.showConfig,
						});
					}}>
					<S.IconActionSub>
						<i className={workflowIcons(item.type ?? WorkFlowTypeEnum.Default)}></i>
					</S.IconActionSub>
					<S.Text>{item?.text}</S.Text>
				</S.Content>
				{item.showConfig ? (
					<S.SubContent>
						{workflowSubcomponent(
							item.type ?? WorkFlowTypeEnum.Default,
							item,
							workFlowItem,
							setWorkFlowItem,
						)}
					</S.SubContent>
				) : (
					<></>
				)}
			</S.SubComponent>
		);
	});
};
