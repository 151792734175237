/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {IExpression, IOption, IWorkFlowItem} from 'src/@types/app';
import {ComponentSimpleEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import * as S from '../styles';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {setWorkFlowValues} from '../helper';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import ExpressionItem from 'src/pages/builder/Builder/components/Expression/ExpressionItem';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const SetValueComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [fields, setFields] = useState<IOption[]>([]);

	useEffect(() => {
		const mFields = application.resources
			?.find(x => x.selected)
			?.widgets?.filter(x =>
				[
					ComponentSimpleEnum.Input,
					ComponentSimpleEnum.TextArea,
					ComponentSimpleEnum.InputDate,
					ComponentSimpleEnum.InputTag,
				].includes(x.name as any),
			);

		setFields(
			mFields?.map(item => ({
				value: item.properties.name,
				label: item.properties.name,
				custom: item.properties,
			})) ?? [],
		);
	}, []);

	const saveExp = (data: IExpression) => {
		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'expression', data);
	};

	return (
		<S.ContentBasic>
			<SystemSelect
				value={fields.find(x => x.value === item.values?.field)}
				options={fields}
				onChange={(value: any) => {
					setWorkFlowValues(
						workFlowItem,
						setWorkFlowItem,
						item.ref!,
						'field',
						value.value,
					);
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'value', undefined);

					const columnsNames: IOption[] = [];

					value.custom?.finderData?.propData?.customProperties?.forEach(
						(customProp: any) => {
							const column = application.database?.tables
								?.find(table =>
									table.columns?.find(column => column.ref === customProp.value),
								)
								?.columns?.find(column => column.ref === customProp.value);
							const columnName = backFormatPropertyName(column?.name ?? '');
							columnsNames.push({
								label: columnName,
								value: columnName,
							});
						},
					);
				}}
				label="Campo"
				inputType="minimal"
			/>

			{item.values?.field ? (
				<ExpressionItem
					currentItem={item?.values?.expression ?? {}}
					inputType="minimal"
					useWorkflow={true}
					workFlowItem={workFlowItem}
					customSave={saveExp}
				/>
			) : (
				<></>
			)}
		</S.ContentBasic>
	);
};
