/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IComponent, IResource, ISubGenerate} from 'src/@types/app';
import {ComponentSimpleEnum} from 'src/@types/enums';
import {GET_REF} from '../engine-enum';
import {addAction} from './addAction';
import {addProperties} from './addProperties';
import {addState} from './addState';
import {addImport} from './getSetImports';

export const addWidget = (
	widget: IComponent,
	bodyString: string,
	widgets: IComponent[],
	widgetRefs: string[],
	widgetImports: string[],
	documentsState: string[],
	iconImports: string[],
	apiImports: string[],
	app: IApplication,
	fileInstances: string[],
	instanceContext: string[],
	importContext: string[],
	allSubcomponents: ISubGenerate[],
	importSubcomponents: string[],
	documentType: string[],
	documentErrorType: string[],
	resource: IResource,
	anotherImports: string[],
	isSubComponent = false,
): string => {
	const props = addProperties(
		widget,
		iconImports,
		widgets,
		allSubcomponents,
		importSubcomponents,
		isSubComponent,
		fileInstances,
		apiImports,
		app,
		widgetImports,
		anotherImports,
	);
	let widgetString = '';

	if (widget.options && widget.options.hasOwnProperty('children')) {
		widgetRefs.push(widget.ref!);
		widgetString = `
			<${widget.name} ${props}>
				${GET_REF(widget.ref!)}
			</${widget.name}>
    `;
	} else {
		let action = addAction(
			widgets,
			widgetImports,
			apiImports,
			app,
			fileInstances,
			instanceContext,
			importContext,
			widget,
			undefined,
			resource,
		);

		if (action === '' && widget.name === ComponentSimpleEnum.Button)
			action = `onClick={clearPage}`;
		widgetString = `<${widget.name} ${props} ${action} />`;
	}

	if (!widget.parentRef) bodyString += widgetString;
	else {
		const parentRef = GET_REF(widget.tabRef ?? widget.parentRef);
		widgetString += `\n${parentRef}`;
		bodyString = bodyString.replace(parentRef, widgetString);
	}

	addState(app, widget, documentsState, documentType, documentErrorType);
	addImport(widget, widgetImports, anotherImports);

	return bodyString;
};
