import {IExpression} from 'src/@types/app';
import {ExpressionFunctionEnum} from 'src/@types/enums';

export const builderJavaScriptExpression = (expression: IExpression): string => {
	const data = [...(expression.items ?? [])];
	let code = ``;

	data.forEach((expression, index) => {
		let isNumber = false;
		if (
			(index > 0 && data[index - 1]?.type === ExpressionFunctionEnum.Arithmetic) ||
			data[index + 1]?.type === ExpressionFunctionEnum.Arithmetic
		)
			isNumber = true;

		if (expression.type === ExpressionFunctionEnum.Field) {
			let prop = expression.propName;
			const isVariable = prop?.includes('var -');
			const isEnv = prop?.includes('env -');

			if (isVariable) {
				prop = prop?.replace('var - ', '');
				if (expression.subPropName) prop = `${prop}.${expression.subPropName}`;

				if (isNumber) code += `Number(${prop})`;
				else code += prop;
			} else if (isEnv) {
				prop = prop?.replace('env - ', '');
				code += `authUser.${prop}`;
			} else {
				if (isNumber) code += `Number(${prop})`;
				else code += `formData?.${prop}`;
			}
		} else if (expression.type === ExpressionFunctionEnum.FreeText) {
			if (isNumber) code += `Number("${expression.value ?? '0'}")`;
			else code += `"${expression.value ?? ''}"`;
		} else if (expression.type === ExpressionFunctionEnum.UpperCase) code += `?.toUpperCase()`;
		else if (expression.type === ExpressionFunctionEnum.LowerCase) code += `?.toLowerCase()`;
		else if (expression.type === ExpressionFunctionEnum.Arithmetic)
			code += ` ${expression.value} `;
		else if (expression.type === ExpressionFunctionEnum.Concat) code += ` + `;
		else if (expression.type === ExpressionFunctionEnum.Comparetor)
			code += ` ${expression.value} `;
		else if (expression.type === ExpressionFunctionEnum.DataProp)
			code += `x.${expression.propName}`;
	});

	return code;
};
