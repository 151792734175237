import {IWidgetState} from 'src/@types/app';

export const USE_STATE = '{TEMPLATE:USE_STATE}';
export const USE_EFFECT = '{TEMPLATE:USE_EFFECT}';
export const USE_EFFECT_EXP = '{TEMPLATE:USE_EFFECT_EXP}';
export const USE_EFFECT_WORKFLOW = '{TEMPLATE:USE_EFFECT_WORKFLOW}';
export const BODY = '{TEMPLATE:BODY}';
export const IMPORT_AREA = '{TEMPLATE_IMPORT_AREA}';
export const INSTANCES_AREA = '{INSTANCES_AREA}';
export const USE_CONTEXT = '{USE_CONTEXT}';
export const DATA_API = '{DATA_API}';
export const FORM_DATA_TYPE = '{FORM_DATA_TYPE}';
export const FORM_DATA_ERROR_TYPE = '{FORM_DATA_ERROR_TYPE}';
export const FORM_VALIDATION = '{FORM_VALIDATION}';

export const GET_REF = (ref: string) => `{REF:${ref}}`;

export const STATE_OBJECTS: IWidgetState[] = [
	{name: 'Input', type: 'string'},
	{name: 'InputDate', type: 'Date'},
	{name: 'InputTag', type: 'string[]'},
	{name: 'Select', type: 'string'},
	{name: 'Autocomplete', type: 'string'},
	{name: 'Checkbox', type: 'boolean'},
	{name: 'Switch', type: 'boolean'},
	{name: 'DataGrid', type: 'any[]'},
	{name: 'UploadFile', type: 'any[]'},
	{name: 'TextArea', type: 'string'},
	{name: 'Address', type: 'IAddress'},
];

export const EXCLUDE_PROPERTIES: string[] = [
	'propertyMap',
	'name',
	'propertyExcludedMap',
	'propertyEditableMap',
	'dataRef',
	'propRef',
	'optionType',
	'tableMap',
	'dependencyList',
	'dataColumns',
	'internalName',
	'addressMap',
	'validationGroup',
];

export const ICON_PROPS: string[] = ['icon', 'leftIcon'];
