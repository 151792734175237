/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {HttpComp} from './component/httpComp';
import {VariableComp} from './component/variableComp';
import {CrudComp, CrudOptions} from './component/crudComp';
import {ConditionComp} from './component/conditionComp';
import {IApplication, IOption, IWorkFlowItem} from 'src/@types/app';
import {AttrVariableComp} from './component/attrVariableComp';
import {IfElseComp} from './component/ifelseComp';
import {ForComp} from './component/forComp';
import {getWidgetsInput} from '../methods/app-methods';
import {EmailComp} from './component/emailComp';
import {FtpComp} from './component/ftpComp';
import {AmqpComp} from './component/amqpComp';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {EnableDisableFieldsComp} from './component/enableDisableFieldsComp';
import {ShowHideFieldsComp} from './component/showHideComp';
import {AlertComp} from './component/alertComp';
import {AlertConfirmComp} from './component/alertConfirmComp';
import {lowerFirstWord} from '../methods/text-methods';
import {createWorkflowArrow} from './component/workflowArrow';
import {createWorkflowItemInput} from './workflowInput';
import {createWorkflowItemFront} from './workflowFront';
import {createWorkflowItemData} from './workflowData';
import {SetAutocompleteValueComp} from './component/setAutocompleteValueComp';
import {SetValueComp} from './component/setValueComp';

export const workflowTitle = (type: WorkFlowTypeEnum) => {
	switch (type) {
		case WorkFlowTypeEnum.Variable:
			return 'Variável';
		case WorkFlowTypeEnum.AttrVariable:
			return 'Atribuir variável';
		case WorkFlowTypeEnum.CRUD:
			return 'Dados';
		case WorkFlowTypeEnum.Condition:
			return 'Condição';
		case WorkFlowTypeEnum.Http:
			return 'HTTP';
		case WorkFlowTypeEnum.IfYes:
			return 'lni lni-checkmark';
		case WorkFlowTypeEnum.IfNo:
			return 'lni lni-close';
		case WorkFlowTypeEnum.For:
			return 'Loop For';
		case WorkFlowTypeEnum.Email:
			return 'E-mail';
		case WorkFlowTypeEnum.Amqp:
			return 'AMQP';
		case WorkFlowTypeEnum.Ftp:
			return 'FTP';
		case WorkFlowTypeEnum.EnableDisableFields:
			return 'Habilitar/Desabilitar campos';
		case WorkFlowTypeEnum.ShowHideFields:
			return 'Exibir/Ocultar campos';
		case WorkFlowTypeEnum.Alert:
			return 'Alerta';
		case WorkFlowTypeEnum.AlertConfirm:
			return 'Alerta confirmação';
		case WorkFlowTypeEnum.SetAutocompleteValue:
			return 'Adicionar valor do autocomplete a um campo';
		case WorkFlowTypeEnum.SetValue:
			return 'Informar valor em um campo';
		default:
			return 'lni lni-code';
	}
};

export const workflowIcons = (type: WorkFlowTypeEnum) => {
	switch (type) {
		case WorkFlowTypeEnum.Variable:
			return 'lni lni-code';
		case WorkFlowTypeEnum.AttrVariable:
			return 'lni lni-shortcode';
		case WorkFlowTypeEnum.CRUD:
			return 'lni lni-database';
		case WorkFlowTypeEnum.Condition:
			return 'lni lni-funnel';
		case WorkFlowTypeEnum.Http:
			return 'lni lni-world';
		case WorkFlowTypeEnum.IfYes:
			return 'lni lni-checkmark';
		case WorkFlowTypeEnum.IfNo:
			return 'lni lni-close';
		case WorkFlowTypeEnum.For:
			return 'lni lni-reload';
		case WorkFlowTypeEnum.Email:
			return 'lni lni-envelope';
		case WorkFlowTypeEnum.Amqp:
			return 'lni lni-cloud-sync';
		case WorkFlowTypeEnum.Ftp:
			return 'lni lni-files';
		case WorkFlowTypeEnum.EnableDisableFields:
			return 'lni lni-eye';
		case WorkFlowTypeEnum.ShowHideFields:
			return 'lni lni-eye';
		case WorkFlowTypeEnum.Alert:
			return 'lni lni-popup';
		case WorkFlowTypeEnum.AlertConfirm:
			return 'lni lni-popup';
		default:
			return 'lni lni-code';
	}
};

export const workflowSubcomponent = (
	type: WorkFlowTypeEnum,
	item: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
) => {
	switch (type) {
		case WorkFlowTypeEnum.Http:
			return (
				<HttpComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Variable:
			return (
				<VariableComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.CRUD:
			return (
				<CrudComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Condition:
			return (
				<ConditionComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.AttrVariable:
			return (
				<AttrVariableComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.IfYes:
		case WorkFlowTypeEnum.IfNo:
			return (
				<IfElseComp
					workFlowItem={workFlowItem}
					item={item}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.For:
			return (
				<ForComp
					workFlowItem={workFlowItem}
					item={item}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Email:
			return (
				<EmailComp
					workFlowItem={workFlowItem}
					item={item}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Amqp:
			return (
				<AmqpComp
					workFlowItem={workFlowItem}
					item={item}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Ftp:
			return (
				<FtpComp
					workFlowItem={workFlowItem}
					item={item}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.EnableDisableFields:
			return (
				<EnableDisableFieldsComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.ShowHideFields:
			return (
				<ShowHideFieldsComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.Alert:
			return (
				<AlertComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.AlertConfirm:
			return (
				<AlertConfirmComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.SetAutocompleteValue:
			return (
				<SetAutocompleteValueComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		case WorkFlowTypeEnum.SetValue:
			return (
				<SetValueComp
					item={item}
					workFlowItem={workFlowItem}
					setWorkFlowItem={setWorkFlowItem}
				/>
			);
		default:
			return <></>;
	}
};

export const setWorkFlowValues = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	ref: string,
	propName: string,
	value: any,
) => {
	const data = [...workFlowItem].map(x => {
		if (x.ref === ref) {
			x.values = {...(x.values ?? {}), [propName]: value};
		}
		return x;
	});

	setWorkFlowItem(data);
};

const getColumnPropsToSubField = (app: IApplication, tableRef: string): IOption[] => {
	return [
		{
			value: undefined,
			label: 'Selecione...',
		},
		...(app.database?.tables
			?.find(x => x.ref === tableRef)
			?.columns?.map(column => ({
				label: backFormatPropertyName(column.name ?? ''),
				value: column.ref,
			})) ?? []),
	];
};

export const getOptionFieldsList = (
	app: IApplication,
	workFlowItem: IWorkFlowItem[],
	item: IWorkFlowItem,
	isSetVariable = false,
): IOption[] => {
	const options = getWidgetsInput(app);
	const hasParentFor = checkHasParentFor(item, workFlowItem);

	workFlowItem.forEach(flowItem => {
		if (
			flowItem.type === WorkFlowTypeEnum.Variable &&
			flowItem.values &&
			flowItem.values.variableName
		) {
			options.push({
				value: flowItem.ref,
				label: `var - ${flowItem.values.variableName}`,
			});
		} else if (
			flowItem.type === WorkFlowTypeEnum.CRUD &&
			flowItem.values &&
			flowItem.values.variableName &&
			flowItem.ref != item.ref
		) {
			options.push({
				value: flowItem.ref,
				label: `var - ${flowItem.values.variableName}`,
				custom:
					flowItem.values.operation != CrudOptions.List
						? {
								ident: 'TABLE_PROPS',
								value: flowItem.values.tableRef,
								value2: getColumnPropsToSubField(app, flowItem.values.tableRef),
						  }
						: undefined,
			});
		}
	});

	if (!isSetVariable) {
		const envs = getEnvironmentOptionsList(app);
		options.push(...envs);
	}

	if (hasParentFor && hasParentFor.status) {
		const parentFor = workFlowItem.find(x => x.ref === hasParentFor.forRef);
		const parentData = workFlowItem.find(x => x.ref === parentFor?.values.property.value);
		const tableRef = parentData?.values.tableRef;

		options.push({
			value: hasParentFor.forRef,
			label: `for - item`,
			custom: {
				ident: 'TABLE_PROPS',
				value: tableRef,
				value2: getColumnPropsToSubField(app, tableRef),
			},
		});
	}

	return options;
};

export const getOptionFieldsListAlternative = (
	app: IApplication,
	workFlowItem: IWorkFlowItem[],
	isSetVariable = false,
): IOption[] => {
	const options = getWidgetsInput(app);

	workFlowItem.forEach(flowItem => {
		if (
			flowItem.type === WorkFlowTypeEnum.Variable &&
			flowItem.values &&
			flowItem.values.variableName
		) {
			options.push({
				value: flowItem.ref,
				label: `var - ${flowItem.values.variableName}`,
			});
		}
	});

	if (!isSetVariable) {
		const envs = getEnvironmentOptionsList(app);
		options.push(...envs);
	}

	return options;
};

const checkHasParentFor = (
	item: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
): {status: boolean; forRef?: string} => {
	if (item.refItem) {
		const parent = workFlowItem.find(x => x.ref === item.refItem);
		if (!parent) return {status: false};

		if (parent.type === WorkFlowTypeEnum.For) {
			return {status: true, forRef: parent.ref};
		} else if (parent.refItem) return checkHasParentFor(parent, workFlowItem);
	}

	return {status: false};
};

export const isDisableOperations = (operation: string) =>
	['!= null', '== null'].includes(operation);

export const isDisableOperationsJavaScript = (operation: string) =>
	['!= null', '== null'].includes(operation);

export const isDisableOperations2 = (operation: string) =>
	['!= null', '== null', '!', '!!'].includes(operation);

export const isOperationsJavaScript = (opration: string) => ['!', '!!'].includes(opration);

export const getEnvironmentOptionsList = (application: IApplication): IOption[] => {
	const data: IOption[] = [];
	application.userConfiguration?.mapping
		?.filter(mapItem => mapItem.isEnvironment)
		.forEach(mapItem => {
			data.push({
				label: `env - ${lowerFirstWord(mapItem.columnName ?? '')}`,
				value: `user.${lowerFirstWord(mapItem.columnName ?? '')}`,
				custom: {
					ident: 'ENV',
				},
			});
		});

	application.userConfiguration?.mapCustom?.forEach(mapItem => {
		const lastItem = mapItem.customMapVars[mapItem.customMapVars.length - 1];
		data.push({
			label: `env - ${lastItem.table?.label}.${lastItem.column?.label}`,
			value: `user.${lastItem.table?.label}${lastItem.column?.label}`,
			custom: {
				ident: 'ENV',
			},
		});
	});

	return data;
};

const getWorkflowItem = (
	item: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	type: WorkFlowDataTypeEnum,
): any => {
	if (type === WorkFlowDataTypeEnum.Data)
		return createWorkflowItemData(item, workFlowItem, setWorkFlowItem);
	if (type === WorkFlowDataTypeEnum.Front)
		return createWorkflowItemFront(item, workFlowItem, setWorkFlowItem);
	if (type === WorkFlowDataTypeEnum.Input)
		return createWorkflowItemInput(item, workFlowItem, setWorkFlowItem);
};

export const buildWorkFlow = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	addItem: (position: number) => void,
	type: WorkFlowDataTypeEnum,
) => {
	const contentData: JSX.Element[] = [];
	const compFiltered = workFlowItem?.filter(item => !item.refItem);

	compFiltered?.forEach((item, index) => {
		contentData.push(getWorkflowItem(item, workFlowItem, setWorkFlowItem, type));
		if (index != (compFiltered?.length ?? 0) - 1)
			contentData.push(createWorkflowArrow(() => addItem(index)));
	});

	return <>{contentData}</>;
};

export const buildWorkflowSub = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	addItem: (position: number) => void,
	parentRef: string,
	type: WorkFlowDataTypeEnum,
): JSX.Element[] => {
	const contentData: JSX.Element[] = [];
	const compFiltered = workFlowItem.filter(item => item.refItem === parentRef);

	compFiltered?.forEach((item, index) => {
		contentData.push(getWorkflowItem(item, workFlowItem, setWorkFlowItem, type));
		if (index != (compFiltered?.length ?? 0) - 1)
			contentData.push(createWorkflowArrow(() => addItem(index)));
	});

	return contentData;
};
