/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IOption} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {Button, IconButton, ModalTitle, Switch, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {applicationTheme} from 'src/shared/theme';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import * as S from './styles';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {getSelectedWidget} from 'src/helpers/methods/app-methods';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

type AddressMap = {
	ref?: string;
	name?: string;
	field?: string;
	parentRef?: string;
	isParent?: boolean;
};

const ListFields: IOption[] = [
	{
		label: 'Cep',
		value: 'Cep',
	},
	{
		label: 'Logradouro',
		value: 'Logradouro',
	},
	{
		label: 'Número',
		value: 'Número',
	},
	{
		label: 'Complemento',
		value: 'Complemento',
	},
	{
		label: 'Bairro',
		value: 'Bairro',
	},
	{
		label: 'UF',
		value: 'UF',
	},
	{
		label: 'Localidade',
		value: 'Localidade',
	},
];

export const AddAddressMapModal = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [maps, setMaps] = useState<AddressMap[]>([]);
	const [map, setMap] = useState<AddressMap>({});

	useEffect(() => {
		const selectedWidget = getSelectedWidget(application, undefined);
		setMaps(selectedWidget?.properties?.addressMap ?? []);
	}, [application]);

	const handleAdd = () => {
		if (!map || !map.name) return;
		if (!map.isParent && !map.field) return;

		const mMaps = [...maps];
		mMaps.push({
			...map,
			ref: crypto.randomUUID(),
		});
		setMaps(mMaps);
		setMap({});
	};

	const handleRemove = (ref: string) => {
		const mMaps = [...maps].filter(x => x.ref !== ref).filter(x => x.parentRef !== ref);
		setMaps(mMaps);
	};

	const handleSave = () => {
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				resource.widgets = resource.widgets?.map(widget => {
					if (widget.selected) {
						if (!widget.properties) widget.properties = {};
						widget.properties.addressMap = maps;
					}
					return widget;
				});

				return resource;
			}),
		});

		close();
	};

	const getParents = (): IOption[] => {
		return maps
			.filter(x => x.isParent)
			.map(x => ({
				value: x.ref,
				label: x.name,
			})) as any;
	};

	const close = () => {
		onClose();
		setMap({});
	};

	const renderItem = (item: AddressMap, index: number, parent?: number): any => {
		let calcParent: number = parent ?? 0;
		if (item.parentRef) calcParent += 1;

		return (
			<div key={index}>
				<S.Item parents={calcParent} textColor={'#000'}>
					<div className="param">
						{item.name}:{' '}
						{item.isParent ? <span></span> : <span>{`"${item.field}"`}</span>}
					</div>
					<Wrapper justifyContent="end">
						<IconButton
							icon={faClose}
							size="12px"
							color={'#000'}
							onClick={() => handleRemove(item!.ref!)}
						/>
					</Wrapper>
				</S.Item>
				{maps
					?.filter(x => x.parentRef === item.ref)
					?.map((subItem, subIndex) => renderItem(subItem, subIndex, calcParent))}
			</div>
		);
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} width="90%" maxWidth="1000px">
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={close}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Mapeamento de endereço" />

					<Wrapper margin="14px 0">
						<SystemInput
							width="60%"
							value={map?.name}
							onChange={value => setMap({...map, name: value})}
							label="Nome do parâmetro"
						/>
						<SystemSelect
							width="40%"
							value={ListFields.find(x => x.value === map?.field)}
							options={ListFields.filter(
								x => !maps.map(y => y.field).includes(x.value),
							)}
							onChange={value => setMap({...map, field: value.value})}
							label="Campo"
							disabled={map?.isParent}
						/>
						<SystemSelect
							width="25%"
							value={getParents().find(x => x.value === map?.parentRef)}
							options={getParents()}
							onChange={value => setMap({...map, parentRef: value.value})}
							label="Pai"
						/>
						<Switch
							handleToggle={() =>
								setMap({...map, field: undefined, isParent: !map?.isParent})
							}
							isOn={map?.isParent}
							text="É pai?"
						/>
					</Wrapper>
					<Wrapper justifyContent="end">
						<WidthBox width="auto" margin="0">
							<Button
								icon2={'plus'}
								onClick={handleAdd}
								fill="auto"
								textColor={applicationTheme.brand[600]}
								background={applicationTheme.brand[100]}
							/>
						</WidthBox>
					</Wrapper>

					<S.BodyContent>
						{maps
							?.filter(x => !x.parentRef)
							?.map((item, index) => renderItem(item, index))}
					</S.BodyContent>

					<Wrapper margin="25px 0 0 0" justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
							<Button
								text="Salvar"
								leftIcon2="save"
								onClick={handleSave}
								fill="auto"
								background={applicationTheme.brand[600]}
								disabled={maps.length == 0}
							/>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={close}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};
