/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {components} from 'react-select';
import {getListIcons} from 'src/helpers/select-data/iconList';
import {SystemSelect} from '../../System/SystemSelect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';

type Props = {
	onChange: (value: any) => void;
	background?: string;
	border?: string;
	textColor?: string;
	value?: any;
};

const SelectIcon = ({onChange, background, border, textColor, value}: Props) => {
	const [listOptions, setListOptions] = useState<any>();
	const [filterVal, setFilterVal] = useState('');

	useEffect(() => {
		const data = getListIcons().filter(x =>
			x.label.toLowerCase().includes(filterVal.toLowerCase()),
		);
		setListOptions(data);
	}, [filterVal]);

	const loadMore = () => {
		if (filterVal && filterVal != '') return;
		const all = getListIcons();
		if (listOptions.length < all.length) {
			const data =
				listOptions.length + 100 > all.length
					? all
					: all.splice(0, listOptions.length + 100);
			setListOptions(data);
		}
	};

	const select = (data: any) => {
		onChange(data.value);
		setFilterVal('');
	};

	const {Option} = components;
	const IconOption = (props: any) => (
		<Option {...props}>
			<FontAwesomeIcon icon={fas[props?.data?.value]} /> {props.data.label}
		</Option>
	);

	return (
		<>
			<div style={{width: '100%'}}>
				<SystemSelect
					value={value}
					options={listOptions}
					onChange={select}
					components={{Option: IconOption}}
					onMenuScrollToBottom={loadMore}
					placeholder="Selecione o icone"
				/>
			</div>
		</>
	);
};

export default SelectIcon;
