/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IApplication,
	IComponent,
	IColumn,
	IResource,
	IApi,
	IActionApiMap,
	ITable,
} from 'src/@types/app';
import {
	ActionTypeEnum,
	ComponentComplexEnum,
	ComponentSimpleEnum,
	ComponentTypeEnum,
} from 'src/@types/enums';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import Components, {DataInputComponent} from 'src/shared/components';
import {v4 as uuidV4} from 'uuid';
import {CreateDataGridColumnType} from 'src/pages/builder/Specify/subpages/DataGrid';
import {formatScreenName, RefreshEndpointsWithInherits} from './database-to-screen';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export class DragDropController {
	private application: IApplication;
	private setApplication: (value: IApplication) => void;
	private setLoading: (value: boolean) => void;
	private ATTR = 'data-rbd-draggable-id';
	private originRef = '';
	private destinyRef = '';
	private resourceApi = new ResourceApi();
	private applicationApi = new ApplicationApi();
	private setTabJoin: (value: any) => void;
	private originItem?: IResource;
	private destinyItem?: IResource;
	private apis?: IApi[];
	public originResult?: IResource;
	public destinyResult?: IResource;

	constructor(
		application: IApplication,
		setApplication: (value: IApplication) => void,
		setLoading: (value: boolean) => void,
		setTabJoin: (value: any) => void,
	) {
		this.application = application;
		this.setApplication = setApplication;
		this.setLoading = setLoading;
		this.setTabJoin = setTabJoin;
	}

	public dragGetElementRef = (e: any) => {
		const element: any = document.elementFromPoint(e.clientX, e.clientY);
		this.originRef = element.getAttribute(this.ATTR);

		if (!this.originRef) {
			if (element.nodeName === 'path')
				this.originRef = element.parentElement.parentElement.getAttribute(this.ATTR);
			else if (element.nodeName === 'H3')
				this.originRef = element.parentElement.getAttribute(this.ATTR);
		}
	};

	public dropGetElementRef = (e: any) => {
		const element: any = document.elementFromPoint(e.clientX, e.clientY);
		this.destinyRef = element.getAttribute(this.ATTR);

		if (!this.destinyRef) {
			if (element.nodeName === 'path')
				this.destinyRef = element.parentElement.parentElement.getAttribute(this.ATTR);
			else if (element.nodeName === 'H3')
				this.destinyRef = element.parentElement.getAttribute(this.ATTR);
		}

		if (!this.originRef || !this.destinyRef || this.originRef == this.destinyRef) return;

		this.moveWidgets();
	};

	public transferAndRemoveResource = async (
		originResult: IResource,
		originItem: IResource,
		destinyResult: IResource,
		destinyItem: IResource,
		parentRef?: string,
		tableRef?: string,
	) => {
		const originColumns = this.application?.database?.tables?.find(
			x => x.ref === originItem!.databaseTableRef,
		)?.columns;

		const hasOneToManyRelationship = originColumns?.some(x => x.relationType == '1ToN');

		if (hasOneToManyRelationship) {
			const destinyTableName = formatScreenName(
				this.application?.database?.tables?.find(
					x => x.ref === destinyItem!.databaseTableRef,
				)?.name ?? '',
			);

			destinyResult?.widgets?.forEach((widget: IComponent) => {
				if (
					widget?.properties?.validationGroup === undefined ||
					widget?.properties?.validationGroup === ''
				) {
					widget.properties.validationGroup = destinyTableName;
				}
			});
		}

		this.prepareWidgets(
			originResult?.widgets ?? [],
			originItem!.databaseTableRef,
			originColumns,
			destinyItem!.databaseTableRef,
		).forEach(item =>
			destinyResult?.widgets?.push({
				...item,
				parentRef: !item.parentRef ? parentRef : item.parentRef,
				tabRef: !item.parentRef ? tableRef : item.tabRef,
			}),
		);

		const destinyTableRef = this.application.database?.tables?.find(
			x => x.ref === destinyItem!.databaseTableRef,
		);
		const resourceName = formatScreenName(destinyTableRef?.name ?? '');
		const schema = destinyTableRef?.schema ?? '';
		const api = this.application.apis?.find(x => x.schema === schema);

		['Incluir', 'Alterar'].forEach((item, index) => {
			const endpoint = api?.endpoints?.find(x => x.name == `${item}${resourceName}`);
			const mapRequest: IActionApiMap[] | undefined = destinyResult?.widgets
				?.filter((x: any) => DataInputComponent.includes(x.name))
				.map((item: any) => {
					return {
						targetApiRef:
							endpoint?.request?.find(x => x.name === item.properties.internalName)
								?.ref ?? '',
						targetComponentRef: item.ref ?? '',
					};
				});

			const apiWidget = destinyResult?.widgets
				?.find((x: any) => x.properties.text === item)
				?.actions?.find((x: any) => x.actionType === ActionTypeEnum.Api)?.api;
			if (apiWidget !== undefined) {
				apiWidget.mapRequest = mapRequest;
			}
		});

		await this.resourceApi.updateComplex({
			...destinyResult!,
			id: destinyItem!.id,
		} as any);

		await new ApplicationApi().updateComplex({
			id: this.application.id!,
			database: this.application?.database
				? JSON.stringify(this.application.database)
				: undefined,
			apis: JSON.stringify(this.apis),
			databaseRules: this.application?.databaseRules
				? JSON.stringify(this.application.databaseRules)
				: undefined,
			contextEnvironment: this.application.userConfiguration
				? JSON.stringify(this.application.userConfiguration)
				: undefined,
			environments: this.application.environments
				? JSON.stringify(this.application.environments)
				: undefined,
			amqps: this.application.amqps ? JSON.stringify(this.application.amqps) : undefined,
			caches: this.application.caches ? JSON.stringify(this.application.caches) : undefined,
			emails: this.application.emails ? JSON.stringify(this.application.emails) : undefined,
			ftps: this.application.ftps ? JSON.stringify(this.application.ftps) : undefined,
		});

		await this.resourceApi.delete(originItem!.id!);
	};

	private getTypeElement = (ref: string): 'resource' | 'folder' => {
		const result = this.application.resources?.find(x => x.ref === ref);

		return result ? 'resource' : 'folder';
	};

	private moveWidgets = async () => {
		const origin = this.getTypeElement(this.originRef);
		const destiny = this.getTypeElement(this.destinyRef);

		if (origin === 'folder' && destiny === 'folder') return;

		try {
			this.setLoading(true);

			if (origin === 'resource' && destiny === 'resource') {
				this.originItem = this.application.resources?.find(x => x.ref == this.originRef);
				this.destinyItem = this.application.resources?.find(x => x.ref == this.destinyRef);

				if (!this.originItem || !this.destinyItem) throw Error('Error when find!');

				this.originResult = await this.resourceApi.get(this.originItem.id!);
				if (
					!this.originResult ||
					!this.originResult.widgets ||
					this.originResult.widgets.length == 0
				)
					throw Error('Origin Not found!');

				this.destinyResult = await this.resourceApi.get(this.destinyItem.id!);
				if (!this.destinyResult) throw Error('Destiny Not found!');
				if (!this.destinyResult.widgets) this.destinyResult.widgets = [];
				let findedTab = false;
				this.destinyResult.widgets.forEach(widget => {
					if (widget.name === ComponentComplexEnum.Tab) {
						this.setTabJoin({
							show: true,
							resources: this.destinyResult,
							originResult: this.originResult,
							originItem: this.originItem,
							destinyResult: this.destinyResult,
							destinyItem: this.destinyItem,
						});
						findedTab = true;
						return;
					}
				});

				if (!findedTab)
					await this.transferAndRemoveResource(
						this.originResult,
						this.originItem,
						this.destinyResult,
						this.destinyItem,
					);
			} else if (origin === 'resource' && destiny === 'folder') {
				const path = this.application.folders?.find(x => x.selected);
				const resource = this.application.resources?.find(
					x => x.ref === this.originRef,
				) as IResource;
				const folder = this.application.folders?.find(x => x.ref === this.destinyRef);

				const localFolder = path
					? `${path.path ? path.path : ''}/${path.name}/${folder?.name}`
					: `/${folder?.name}`;

				await this.resourceApi.update({
					id: resource.id!,
					name: resource.name ?? '',
					menu: JSON.stringify({
						use: resource.menu!.use,
						name: resource.menu!.name,
						icon: resource.menu!.icon,
					}),
					width: resource.width ?? '',
					isLogin: resource.isLogin ?? false,
					isRoot: resource.isRoot ?? false,
					path: localFolder,
					type: resource.type!,
					useDefaultLayout: resource.useDefaultLayout ?? true,
					databaseTableRef: resource.databaseTableRef ?? '',
				});
			}
		} finally {
			const app = await this.applicationApi.getResources(this.application);
			this.setApplication(app!);
			this.setLoading(false);
		}
	};

	private prepareWidgets = (
		widgets: IComponent[],
		originTableRef?: string,
		columns?: IColumn[],
		destinyTableRef?: string,
	): IComponent[] => {
		let newWidgets = structuredClone(widgets);
		let datagridName: string | undefined = undefined;

		const hasTableRef = !!originTableRef;

		if (hasTableRef) {
			let allowColumns: IColumn[] = columns!;

			this.application?.databaseRules?.forEach(rule => {
				columns?.forEach(column => {
					if (column.name?.toUpperCase() == rule.findedName!.toUpperCase()) {
						allowColumns = allowColumns.filter(x => x.ref !== column.ref);
					}

					if (
						column.name?.toUpperCase().lastIndexOf(rule.findedName!.toUpperCase()) === 0
					) {
						allowColumns = allowColumns.filter(x => x.ref !== column.ref);
					}
				});
			});

			columns = allowColumns;
			newWidgets = this.removeButtons(newWidgets);

			if (columns && destinyTableRef) {
				newWidgets = this.removeForeignKeyWidget(newWidgets, columns, destinyTableRef);
			}

			const hasOneToManyRelationship = columns?.some(x => x.relationType == '1ToN');

			if (hasOneToManyRelationship) {
				const originTableName = formatScreenName(
					this.application?.database?.tables?.find(x => x.ref === originTableRef)?.name ??
						'',
				);

				datagridName = `grid${originTableName}`;

				newWidgets = this.buildDataGrid(
					newWidgets,
					datagridName,
					columns?.filter(
						x =>
							!!x.autoIncremente === false &&
							!!x.isPrimaryKey === false &&
							x.relationType === undefined,
					),
				);

				newWidgets.forEach((widget: IComponent) => {
					if (
						widget?.properties?.validationGroup === undefined ||
						widget?.properties?.validationGroup === ''
					) {
						widget.properties.validationGroup = originTableName;
					}
				});
			}

			this.apis = RefreshEndpointsWithInherits(
				this.application,
				hasOneToManyRelationship!,
				originTableRef!,
				destinyTableRef!,
				datagridName,
				columns?.filter(x => !!x.autoIncremente == false && x.relationType === undefined),
			);
		}

		return newWidgets;
	};

	private removeButtons = (widgets: IComponent[]): IComponent[] => {
		const removedButtonName = ['Incluir', 'Alterar', 'Remover', 'Consultar'];
		const parentWidgetRef = widgets.find(x =>
			removedButtonName.includes(x.properties.text),
		)?.parentRef;

		widgets = widgets.filter(
			x => !removedButtonName.includes(x.properties.text) && x.ref !== parentWidgetRef,
		);

		return widgets;
	};

	private removeForeignKeyWidget = (
		widgets: IComponent[],
		columns: IColumn[],
		destinyTableRef: string,
	): IComponent[] => {
		const parentRefToRemove: string[] = [];
		const removeRefs = widgets
			.filter(
				widget =>
					columns.find(column => column.ref === widget.columnRef)?.constraint ===
					destinyTableRef,
			)
			.map(x => x.ref);

		removeRefs.forEach(item => {
			const parentRef = widgets.find(x => x.ref === item)?.parentRef;
			if (parentRef) parentRefToRemove.push(parentRef);
		});

		widgets = widgets.filter(x => !removeRefs.includes(x.ref));
		widgets = widgets.filter(x => !parentRefToRemove.includes(x.ref!));

		return widgets;
	};

	private buildDataGrid = (
		widgets: IComponent[],
		datagridName?: string,
		columns?: IColumn[],
	): IComponent[] => {
		const datagridRef = uuidV4().toString();

		const dataColumnsRelationship = columns?.map(
			item =>
				<CreateDataGridColumnType>{
					flex: '1',
					header: item.suggestName,
					field: backFormatPropertyName(item.name!),
					id: uuidV4().toString(),
					columnType: 1,
				},
		);

		const columnsRelationship = dataColumnsRelationship?.map(item => ({
			flex: Number(item.flex),
			type: 'string',
			filterable: true,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			field: item.field,
			headerName: item.header,
			renderCell: ({row}: any) => row[item.field!],
		}));

		columnsRelationship?.push({
			flex: Number(1),
			type: 'string',
			filterable: true,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			field: undefined,
			headerName: undefined,
			renderCell: ({row}: any) => '',
		});

		dataColumnsRelationship?.push({
			id: uuidV4().toString(),
			name: 'Excluir',
			columnType: 2,
			actionType: {
				value: 'Remover',
				label: 'Remover',
			},
			icon: {
				label: 'trash-2',
				value: 'trash-2',
			},
		});

		widgets = this.buildButtonActionDataGrid(widgets, datagridName!, datagridRef, columns);

		widgets.push(this.getWrapper({margin: '0 0 12px 0'}));

		const lastRow = this.getLastRow(widgets);

		const widthBox: any = {
			...(Components.find(x => x.name === ComponentSimpleEnum.WidthBox) ?? {}),
			ref: uuidV4().toString(),
			properties: {},
			parentRef: lastRow.ref,
		};

		const grid: any = {
			...(Components.find(x => x.name === ComponentComplexEnum.DataGrid) ?? {}),
			ref: datagridRef,
			parentRef: widthBox.ref,
			type: 2,
			name: ComponentComplexEnum.DataGrid,
			icon: 'grid',
			properties: {
				name: datagridName,
				//bodyBackground: '#d6d6d9',
				//bodyTextColor: '#000',
				//headerTextColor: '#000',
				//template: '1fr',
				columns: columnsRelationship,
				dataColumns: dataColumnsRelationship,
				rowsData: [],
			},
		};

		widgets.push(widthBox);
		widgets.push(grid);

		return widgets;
	};

	private buildButtonActionDataGrid = (
		widgets: IComponent[],
		datagridName: string,
		datagridRef: string,
		columns?: IColumn[],
	): IComponent[] => {
		const lastRow = this.getLastRow(widgets);

		const layoutProperties =
			this.application.layout?.templateConfig &&
			this.application.layout?.templateConfig['Button']
				? this.application.layout?.templateConfig['Button']
				: {};

		const dataColumnsRelationship = columns?.map(item => ({
			propertyName: backFormatPropertyName(item.name!),
			componentRef: widgets.find(x => x.columnRef == item.ref)?.ref,
		}));

		const widthBox: any = {
			...(Components.find(x => x.name === ComponentSimpleEnum.WidthBox) ?? {}),
			ref: uuidV4().toString(),
			properties: {
				width: '65px',
				mask: 'Nenhuma',
			},
			parentRef: lastRow.ref,
		};

		const action = {
			actionName: 'Configurar GRID',
			actionType: ActionTypeEnum.DataGrid,
			items: dataColumnsRelationship,
			ref: uuidV4().toString(),
			targetRef: datagridRef,
		};

		const button: any = {
			...Components.find(x => x.name === ComponentSimpleEnum.Button),
			ref: uuidV4().toString(),
			parentRef: widthBox.ref,
			properties: {
				...layoutProperties,
				fill: 'auto',
				name: `btnAdd${datagridName}`,
				text: '+',
				mask: 'Nenhuma',
				onClick: () => console.log('sem ação'),
			},
			actions: [action],
		};

		widgets.push(widthBox);
		widgets.push(button);

		return widgets;
	};

	private getWrapper = (customProps = {}): any => {
		return {
			...(Components.find(x => x.name === ComponentSimpleEnum.Wrapper) ?? {}),
			ref: uuidV4().toString(),
			properties: customProps,
		};
	};

	private getLastRow = (widgets: IComponent[]) =>
		widgets
			.filter(
				x =>
					x.type === ComponentTypeEnum.Container &&
					x.name === ComponentSimpleEnum.Wrapper,
			)
			.reverse()[0];
}
